import React, { useEffect, useState } from "react";
import { Icon, Button, Col, RSelect } from "../../../components/Component";
import { Modal, ModalBody, Form, Spinner, Row, InputGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import Required from "../../components/Required";
import PuffLoader from "react-spinners/PuffLoader";
import Switch from "react-switch";
import { toast } from "react-toastify";
const FormModal = ({ formData,modal, closeModal, onSubmit, setFormData, modalType, setLoading, loading,setMonthlyAction, setYearlyAction  }) => {
  useEffect(() => {
    reset(formData);
  }, [formData]);
  

  const {
    reset,
      register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  

  useEffect(() => {
    if (formData) {
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
      setPlanStates(formData.status === "1");
    }
  }, [formData, setValue]);
  const defaultOptions = [
    { value: "m", label: "Monthly" },
    { value: "y", label: "Yearly" },
  ];
  const [checkData, setCheckData] = useState({});

  const handleCheckboxChange = (event, feature) => {
    const { checked } = event.target;
    setCheckData((prevFormData) => ({
      ...prevFormData,
      [feature.key]: checked ? "yes" : "no",
    }));
  };
  const [planStates, setPlanStates] = useState(false);

  const handleToggle = () => {
    setPlanStates((prevState) => !prevState);
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: planStates ? "0" : "1",
    }));
  };

  const blockInvalidChar = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.length >= 5 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 17 &&
      e.keyCode !== 65 &&
      e.keyCode !== 9 &&
      e.keyCode !== 86 &&
      e.keyCode !== 88 &&
      e.keyCode !== 67
    ) {
      e.preventDefault();
    }
  };

  const checkKeys = (e) => {
    const inputValue = e.target.value;
    let invalidChars = ["-", "e", "E", ".", "+"];
    if (invalidChars.includes(e.key)) {
      e.preventDefault();
    }
  };

  const blockInvalidCharEmp = (e) => {
    const inputValue = e.target.value;
    if (
      inputValue.length >= 5 &&
      e.keyCode !== 8 &&
      e.keyCode !== 46 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 17 &&
      e.keyCode !== 65 &&
      e.keyCode !== 9 &&
      e.keyCode !== 86 &&
      e.keyCode !== 88 &&
      e.keyCode !== 67
    ) {
      e.preventDefault();
    }
  };

  const handleMonthlyBasePriceChange = (e) => {
    const basePriceInput = parseFloat(e.target.value) || ""; 
    const basePrice = parseFloat(basePriceInput) || 0; 
    const monthlyDiscount = parseFloat(formData.monthly_discount) || 0;
    const discountedPrice =
      basePriceInput === "" ? "" : basePrice - (basePrice * monthlyDiscount) / 100;
  
    setFormData((prev) => ({
      ...prev,
      monthly_price: basePriceInput,
      monthly_discount_price:
        basePriceInput === "" ? "" : discountedPrice.toFixed(2),
    }));
  };
  
  const handleYearlyBasePriceChange = (e) => {
    const yearlyBasePriceInput = e.target.value; 
    const yearlyBasePrice = parseFloat(yearlyBasePriceInput) || 0; 
    const yearlyDiscount = parseFloat(formData.yearly_discount) || 0;
    const yearlyDiscountedPrice =
      yearlyBasePriceInput === ""
        ? ""
        : yearlyDiscount > 0
        ? yearlyBasePrice - (yearlyBasePrice * yearlyDiscount) / 100
        : yearlyBasePrice;
  
    setFormData((prev) => ({
      ...prev,
      yearly_price: yearlyBasePriceInput, 
      yearly_discount_price:
        yearlyBasePriceInput === "" ? "" : yearlyDiscountedPrice.toFixed(2),
    }));
  };
  
  
  const handleMonthlyDiscountChange = (e) => {
    const monthlyDiscount = parseFloat(e.target.value);
    const basePrice = parseFloat(formData.monthly_price);
  
    const discountedPrice = basePrice - (basePrice * monthlyDiscount) / 100;
  
    const yearlyBasePrice = basePrice * 12;
    const yearlyDiscount = parseFloat(formData.yearly_discount);
    const yearlyDiscountedPrice =
      yearlyDiscount > 0 ? yearlyBasePrice - (yearlyBasePrice * yearlyDiscount) / 100 : yearlyBasePrice;
  
    setFormData((prev) => ({
      ...prev,
      monthly_discount: monthlyDiscount,
      monthly_discount_price: discountedPrice.toFixed(2),
      yearly_discount_price: yearlyDiscountedPrice.toFixed(2),
    }));
  };
  
  const handleMonthlyDiscountedPriceChange = (e) => {
    const discountedPrice = parseFloat(e.target.value);
    const basePrice = parseFloat(formData.monthly_price);
  
    const monthlyDiscount = basePrice ? ((basePrice - discountedPrice) / basePrice) * 100 : 0;
  
    const yearlyBasePrice = basePrice * 12;
    const yearlyDiscount = parseFloat(formData.yearly_discount);
    const yearlyDiscountedPrice =
      yearlyDiscount > 0 ? yearlyBasePrice - (yearlyBasePrice * yearlyDiscount) / 100 : yearlyBasePrice;
  
    setFormData((prev) => ({
      ...prev,
      monthly_discount_price: discountedPrice,
      monthly_discount: monthlyDiscount.toFixed(2),
      yearly_discount_price: yearlyDiscountedPrice.toFixed(2),
    }));
  };
  
  const handleYearlyDiscountChange = (e) => {
    const yearlyDiscount = parseFloat(e.target.value);
    const yearlyBasePrice = parseFloat(formData.yearly_price);
  
    const yearlyDiscountedPrice = yearlyBasePrice - (yearlyBasePrice * yearlyDiscount) / 100;
  
    setFormData((prev) => ({
      ...prev,
      yearly_discount: yearlyDiscount,
      yearly_discount_price: yearlyDiscountedPrice.toFixed(2),
    }));
  };
  
  const handleYearlyDiscountedPriceChange = (e) => {
    const yearlyDiscountedPrice = parseFloat(e.target.value);
    const yearlyBasePrice = parseFloat(formData.yearly_price);
  
    const yearlyDiscount = yearlyBasePrice
      ? ((yearlyBasePrice - yearlyDiscountedPrice) / yearlyBasePrice) * 100
      : 0;
  
    setFormData((prev) => ({
      ...prev,
      yearly_discount_price: yearlyDiscountedPrice,
      yearly_discount: yearlyDiscount.toFixed(2),
    }));
  };
 
  const handleChange = (checked) => {
    if (!checked && formData.yearly_action == 0) {
      toast.error("Disabling both plans is not allowed. Please delete the plan if needed.");
      return;
    }
    //console.log(checked);
    
    const value=checked ? 1 : 0
    //console.log("value",value);
    
    setFormData((prev) => ({
      ...prev,
      monthly_action: checked ? 1 : 0,
      monthly_duration: checked ? "y" : "",
    }));
   
    setMonthlyAction(value);
  };
  
  const handleToggleChange = (checked) => {
    if (!checked && formData.monthly_action == 0) {
      toast.error("Disabling both plans is not allowed. Please delete the plan if needed.");
      return;
    }
    const value=checked ? 1 : 0
    //console.log("value:",value);
    setFormData((prev) => ({
      ...prev,
      yearly_action: checked ? 1 : 0,
      yearly_duration: checked ? "y" : "",
    }));
    
    setYearlyAction(value);
  };
  const handleChange1 = (checked) => {
    if (!checked && formData.yearly_action == 0) {
      toast.error("At least one plan must remain active. Disabling both plans is not allowed.");
      return;
    }
    const value=checked ? 1 : 0
    setFormData((prev) => ({
      ...prev,
      monthly_action: checked ? 1 : 0,
      monthly_duration: checked ? "y" : "",
    }));
    
    setMonthlyAction(value);
  };
  
  const handleToggleChange1 = (checked) => {
    if (!checked && formData.monthly_action == 0) {
      toast.error("At least one plan must remain active. Disabling both plans is not allowed.");
      return;
    }
    const value=checked ? 1 : 0
    setFormData((prev) => ({
      ...prev,
      yearly_action: checked ? 1 : 0,
      yearly_duration: checked ? "y" : "",
    }));
    
    setYearlyAction(value);
  };
  
  const [selected, setSelected] = useState("pricing"); 

  const handleSelection = (section) => {  
    setSelected(section);
  };
  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">
            {modalType === "add" && "Add Plan"} {modalType === "edit" && "Update Plan"}
          </h5>
          <div className="mt-5">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
            <Col>
  <div className="form-group d-md-flex align-items-center">
    <Col md="3" lg="2">
      <label className="form-label">Plan Name</label>
      <Required />
    </Col>
   <Col md="9" lg="10">
  <input
    type="text"
    value={formData.name}
    placeholder="Enter plan name"
    maxLength={30}
    onChange={(e) => {
      const value = e.target.value;
      // Validate for alphabets and spaces only
      if (/^[A-Za-z\s]*$/.test(value) || value === "") {
        const capitalizedValue =
          value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        setFormData({ ...formData, name: capitalizedValue });
      }
     
    }}
    
    className={`form-control ${errors.name ? "is-invalid" : ""}`}
    required
  />
  {/* Error message */}
  {!formData.name.trim() && (
    <small className="text-danger">This field is required.</small>
  )}
  
  {errors.name && <small className="text-danger">{errors.name.message}</small>}
</Col>

  </div>
</Col>

              <Row>
              <Col md='12'>
              <div className="btn-group mt-4" aria-label="Basic example"> 
              <button
        type="button"
        className={`btn ${selected === "pricing" ? "btn-primary" : "btn-outline-primary"}`}
        onClick={() => handleSelection("pricing")}
      >
        Pricing
      </button>
      <button
        type="button"
        className={`btn ${selected === "features" ? "btn-primary" : "btn-outline-primary"}`}
        onClick={() => handleSelection("features")}
      >
        Features
        </button>
        </div>
              </Col>
              </Row>
              {selected === "pricing" && (
              <>
              <Row>
  <Col md='6'>
    <div className="border p-3 mt-5">
      <Col md="12">
        <div className="d-flex justify-content-between">
          <div className="fs-5 fw-bold">Monthly</div>
          {modalType !== 'edit' && (
          <Switch
            id="circle-switch"
            checked={formData.monthly_action ==1}
            onChange={handleChange1}
            onColor="#77c96b"
            offColor="#d3d3d3"
            onHandleColor="#36ba00"
            handleDiameter={15}
            width={40}
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          />
        )}
        {modalType !== 'add' && (
          <Switch
            id="circle-switch"
            checked={formData.monthly_action ==1}
            onChange={handleChange}
            onColor="#77c96b"
            offColor="#d3d3d3"
            onHandleColor="#36ba00"
            handleDiameter={15}
            width={40}
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          />)}
        </div>
      </Col>

      <div>
        <Col md="12">
        <div className="form-group mt-4 d-lg-flex">
          <Col lg='6'>
            <label className="form-label">Base Price</label>
            <Required />
          </Col>
          <Col lg='6'>
            <div className="form-control-wrap">
              <div className="input-group">
              <input
                  type="number"
                  onKeyDown={blockInvalidChar}
                  value={formData.monthly_price}
                  onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value > 0 || e.target.value === "") {
                        handleMonthlyBasePriceChange(e);
                      }
                }}
                step="0.01"
                 min="1" 
                 className="form-control"
                 placeholder="Price..."
                 required
               />

                <div className="input-group-prepend ">
                  <span className="input-group-text px-1" id="basic-addon1">USD</span>
                </div>
              </div>
            </div>
          </Col>
          {errors.tasks && <span className="invalid">{errors.monthly_price.message}</span>}
        </div>
      </Col>

      <Col md="12">
        <div className="form-group mt-4 d-lg-flex">
          <Col lg='6'>
            <label className="form-label">Discount</label>
          </Col>
          <Col lg='6'>
            <div className="form-control-wrap">
              <div className="input-group">
                <input
                  type="number"
                  value={formData.monthly_discount}
                  onChange={handleMonthlyDiscountChange} 
                  className="form-control"
                  //placeholder="Enter discount percentage"
                />
                <div className="input-group-prepend">
                  <span className="input-group-text px-2" id="basic-addon1">%</span>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Col>

      <Col md="12">
        <div className="form-group mt-4 d-lg-flex">
          <Col lg='6'>
            <label className="form-label">Discounted Price</label>
          </Col>
          <Col lg='6'>
            <div className="form-control-wrap">
              <div className="input-group">
              <input
                  type="number"
                  value={formData.monthly_discount_price}
                  onChange={handleMonthlyDiscountedPriceChange} 
                  className="form-control"
                  //placeholder="Enter discounted price..."
                />
                <div className="input-group-prepend">
                  <span className="input-group-text px-1" id="basic-addon1">USD</span>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Col>
      </div>
    </div>
  </Col>

  <Col md='6'>
    <div className="border p-3 rounded mt-5">
      <Col md="12">
        <div className="d-flex justify-content-between">
          <div className="fs-5 fw-bold">Yearly</div>
          {modalType !== "edit" && (
          <Switch
            id="circle-switch"
            checked={formData.yearly_action == 1}
            onChange={handleToggleChange1}
            onColor="#77c96b"
            offColor="#d3d3d3"
            onHandleColor="#36ba00"
            handleDiameter={15}
            width={40}
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          />)}
          {modalType !== "add" && (
          <Switch
            id="circle-switch"
            checked={formData.yearly_action ==1}
            onChange={handleToggleChange}
            onColor="#77c96b"
            offColor="#d3d3d3"
            onHandleColor="#36ba00"
            handleDiameter={15}
            width={40}
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          />)}
        </div>
      </Col>

      <Col md="12">
  <div className="form-group mt-4 d-lg-flex">
    <Col lg="6">
      <label className="form-label">Base Price</label>
    </Col>
    <Col lg="6">
      <div className="form-control-wrap">
        <div className="input-group">
          <input
            type="number"
            value={formData.yearly_price}
            onChange={handleYearlyBasePriceChange}
            className="form-control"
            placeholder="Enter yearly base price"
          />
          <div className="input-group-prepend">
            <span className="input-group-text px-1" id="basic-addon1">
              USD
            </span>
          </div>
        </div>
      </div>
    </Col>
  </div>
</Col>

      <Col md="12">
        <div className="form-group mt-4 d-lg-flex">
          <Col lg='6'>
            <label className="form-label">Discount</label>
          </Col>
          <Col lg='6'>
            <div className="form-control-wrap">
              <div className="input-group">
                <input
                  type="number"
                  value={formData.yearly_discount}
                  onChange={handleYearlyDiscountChange}
                  className="form-control"
                  //placeholder="Enter yearly discount percentage"
                />
                <div className="input-group-prepend">
                  <span className="input-group-text px-2" id="basic-addon1">%</span>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Col>

      <Col md="12">
        <div className="form-group mt-4 d-lg-flex">
          <Col lg='6'>
            <label className="form-label">Discounted Price</label>
          </Col>
          <Col lg='6'>
            <div className="form-control-wrap">
              <div className="input-group">
              <input
                  type="number"
                  value={formData.yearly_discount_price}
                  onChange={handleYearlyDiscountedPriceChange} 
                  className="form-control"
                  //placeholder="Enter discounted price..."
                />
                <div className="input-group-prepend">
                  <span className="input-group-text px-1" id="basic-addon1">USD</span>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Col>
    </div>
  </Col>
</Row>

              <div>Note: Changing discounted price can change discount percent</div>
              </>
              )}
              {selected === "features" && (
              <Row style={{paddingTop:'10px',paddingBottom:'10px'}}>
                 
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Business Description Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidCharEmp}
                    {...register("business_description", { required: "This field is required" })}
                    value={formData.business_description}
                    placeholder="Enter Description Limit"
                    onChange={(e) => {
                      const inputDuration =parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, business_description: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.business_description && <span className="invalid">{errors.business_description.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Upload Photos Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidCharEmp}
                    {...register("photos", {
                      required: "This field is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message:  "Only whole numbers (digits) are allowed",
                      },
                    })}
                    value={formData.photos}
                    placeholder="Enter photos count"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, photos: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.photos && <span className="invalid">{errors.photos.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Upload Videos Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("videos", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.videos}
                    placeholder="Enter count for Upload Video"
                    onChange={(e) => {
                      const inputDuration = parseInt (e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, videos: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.videos && <span className="invalid">{errors.videos.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Add Youtube Video Links Limit</label>
                  <input
                    type="text"
                    {...register("youtube_link", {
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Adjusted pattern to allow letters and digits
                        message: "Only letters (A-Z, a-z) and digits (0-9) are allowed",
                      },
                    })}
                    value={formData.youtube_link}
                    placeholder="Enter count for Upload Youtube Video"
                    onChange={(e) => {
                      setFormData({ ...formData, youtube_link: e.target.value.toLowerCase() });
                    }}
                    className="form-control"
                  />
                  {errors.youtube_link && <span className="invalid">{errors.youtube_link.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Upload Mixers Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("mixers", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.mixes}
                    placeholder="Enter count for Mixers"
                    onChange={(e) => {
                      const inputDuration = parseInt(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, mixes: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.mixes && <span className="invalid">{errors.mixes.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Add Social Media Links Limit</label>
                  <input
                    type="text"
                    {...register("social_media_urls", {
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Adjusted pattern to allow letters and digits
                        message: "Only letters (A-Z, a-z) and digits (0-9) are allowed",
                      },
                    })}
                    value={formData.social_media_urls}
                    placeholder="Enter count for Social Media URL's"
                    onChange={(e) => {
                      setFormData({ ...formData, social_media_urls: e.target.value.toLowerCase() });
                    }}
                    className="form-control"
                  />
                  {errors.social_media_urls && <span className="invalid">{errors.social_media_urls.message}</span>}
                </div>
              </Col>
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Maximum Service Listings Limit</label>
                  <input
                    type="text"
                    {...register("service_list", {
                      required: "This field is required",
                    })}
                    value={formData.service_list}
                    placeholder="Enter count for Service Listings"
                    onChange={(e) => {
                      setFormData({ ...formData, service_list: e.target.value });
                    }}
                    className="form-control"
                  />
                  {errors.service_list && <span className="invalid">{errors.service_list.message}</span>}
                </div>
              </Col>
              {/* <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Enter Customized Contact Form Limit</label>
                  <input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    {...register("contact_frm", {
                      pattern: {
                        value: /^\d+$/,
                        message: "Only digits are allowed",
                      },
                    })}
                    value={formData.contact_frm}
                    placeholder="Enter Contact Form for count"
                    onChange={(e) => {
                      const inputDuration = parseFloat(e.target.value);
                      const durationValue = inputDuration < 0 ? 0 : inputDuration;
                      setFormData({ ...formData, contact_frm: durationValue });
                    }}
                    className="form-control"
                  />
                  {errors.contact_frm && <span className="invalid">{errors.contact_frm.message}</span>}
                </div>
              </Col> */}
              <Col md="6" className="mt-2">
                <div className="form-group">
                  <label className="form-label">Live Streaming Limit</label>
                  <Required />
                  <input
                    type="text"
                    {...register("go_live_streaming", {
                      required: "This field is required",
                    })}
                    value={formData.go_live_streaming}
                    placeholder="Enter count for Streaming"
                    onChange={(e) => {
                      setFormData({ ...formData, go_live_streaming: e.target.value });
                    }}
                    className="form-control"
                  />
                  {errors.go_live_streaming && <span className="invalid">{errors.go_live_streaming.message}</span>}
                </div>
              </Col>
               </Row> 
              )}
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={loading}>
                      {modalType === "add" && "Add Plan"} {modalType === "edit" && "Update Plan"}
                      {loading ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className=" btn btn-secondary"
                    >
                      Cancel
                    </Button>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default FormModal;
