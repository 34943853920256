import React, { useEffect, useState } from "react";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  //TooltipComponent,
  Icon
} from "../../components/Component";
import Swal from "sweetalert2";
import Content from "../../layout/content/Content";
import { Modal, ModalBody, Button, ModalHeader, ModalFooter, Input } from "reactstrap";
import { getCookie } from "../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import { SortOrder } from "react-data-table-component";
import './contactList.css'
export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const ContactListPage = () => {
  const [contactData, setContactData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [search, setSearch] = useState("");

  const [pageNo, setPageNo] = useState(0); 
  const [recordLimit, setRecordLimit] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0); 
//console.log("pageno",pageNo)

const fetchData = async () => {
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append("page", pageNo+1);
    formData.append("limit", recordLimit);
    formData.append("search", search);
    //console.log('page No.', pageNo);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/admin/get-contact-list`,
      {
        method: "POST",
        headers: { authToken: getCookie("authToken") },
        body: formData,
      }
    );

    const responseData = await response.json();
    
    if (responseData?.data) {
      setContactData(responseData?.data?.data);
      setTotalRecords(responseData?.data?.pagination?.total || 0);
    } else {
      setContactData([]);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  fetchData();
}, [pageNo, recordLimit, search]);


  //Delete
  const deleteData = async (id) => {
    try {

      const contactExists = contactData.some(contact => contact.id === id);
      if (!contactExists) {
        Swal.fire("Error!", "Contact not found in the list.", "error");
        return;
      }

      const result = await Swal.fire({
        title: "Are you sure to delete the contact?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/admin/delete-contact`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authToken: getCookie("authToken"),
            },
            body: JSON.stringify({ id }),
          }
        );

        const responseData = await response.json();
        //console.log("Response Data:", responseData);  

        if (responseData.status && responseData.message === 'Contact deleted successfully') {
          setContactData((prevData) => prevData.filter((item) => item.id !== id));
          Swal.fire("Deleted!", "The contact list record has been deleted permanently.", "success");
          fetchData()
        } else {
          throw new Error(`Failed to delete contact. Status: ${response.status}`);
        }
      }
    } catch (error) {
      console.error("Error deleting contact:", error);
      Swal.fire("Error!", error.message, "error");
    }
  };

  //send message
  const handleSendMessage = (row) => {
    setCurrentRow(row);
    setMessageText("");
    setIsOverlayOpen(true);
  };
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);

  const handleSendMessageConfirm = async () => {
    if (currentRow && messageText.trim()) {
      try {
        setIsLoadingMessage(true); 
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/admin/send-contact-message`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authToken: getCookie("authToken"),
            },
            body: JSON.stringify({
              id: currentRow.id,
              message: messageText.trim(),
            }),
          }
        );
  
        const responseData = await response.json();
        if (response.ok && responseData.status) {
          Swal.fire("Success!", "The message has been sent.", "success");
          setIsOverlayOpen(false);
          setMessageText("");
        } else {
          Swal.fire("Error!", responseData.message || "Failed to send message", "error");
        }
      } catch (error) {
        Swal.fire("Error!", error.message, "error");
      } finally {
        setIsLoadingMessage(false);  
      }
    } else {
      Swal.fire("Error!", "Please enter a message before sending.", "warning");
    }
  };
  

  const closeOverlay = () => {
    setIsOverlayOpen(false);
  };

  const handleDelete = (row) => {
    deleteData(row.id);
  };

  // const handleSendMessage = (row) => {
  //   setCurrentRow(row);
  //   setMessageText("");
  //   setSendMessageModalOpen(true);
  // };

  // const handleSendMessageConfirm = () => {
  //   if (currentRow && messageText.trim()) {
  //     setSendMessageModalOpen(false);
  //     setMessageText("");
  //   }
  // };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");

  const toggleModal = () => setModalOpen(!modalOpen);
  const handleViewMessage = (message) => {
    setCurrentMessage(message);
    setModalOpen(true);
  };
  const dataTableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (pageNo * recordLimit) + index + 1,
      //selector: (row) => row?.id || <NotAvailable />,
    },
    {
      name: "Name",
      selector: (row) => row?.name || <NotAvailable />,
    },
    {
      name: "Email",
      selector: (row) => row?.email || <NotAvailable />,
    },
    {
      name: "Contact",
      selector: (row) => row?.contact || <NotAvailable />,
    },
    {
      name: "Message",
      cell: (row) => (
        <span
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => handleViewMessage(row?.message)}
        >
          View
        </span>
      ),
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex">
          <button
            className="btn btn-sm border-0"
            onClick={() => handleSendMessage(row)}
            id="sendMessage"
          >
            <Icon name="icon ni ni-send" />
          </button>
          <button
            className="btn btn-sm border-0 me-2"
            onClick={(ev) => {
              ev.preventDefault();
              handleDelete(row);
            }}
            id="deleteMessage"
          >
            <Icon name="trash" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between">
                <BlockTitle tag="h4">Contact List</BlockTitle>
              </div>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <CustomizeReactDataTable
              data={contactData}
              columns={dataTableColumns}
              searchBy={"name"}
              pagination
              actions
              loading={isLoading}
              pageNo={setPageNo}
              recordLimit={setRecordLimit}
              totalRecords={totalRecords}
             activePage={pageNo || 0}
            />
          </PreviewCard>
        </Block>
      </Content>
      {/* View Message */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Message</ModalHeader>
        <ModalBody className="pb-5">
          <p>{currentMessage}</p>
        </ModalBody>
        <ModalFooter className="py-4" />
      </Modal>

      {/* Send Message */}
    
      <Modal isOpen={isOverlayOpen} toggle={closeOverlay}>
  <ModalHeader toggle={closeOverlay}>Send Message</ModalHeader>
  <ModalBody>
    <label>Message</label>
    <Input
      type="textarea"
      value={messageText}
      onChange={(e) => setMessageText(e.target.value)}
      rows="5"
    />
  </ModalBody>
  <ModalFooter>
    {isLoadingMessage ? (
      <Button color="primary" disabled>
        <div className="spinner"></div> {/* Circular Spinner */}
        <span style={{ marginLeft: '10px' }}>Sending...</span>
      </Button>
    ) : (
      <Button color="primary" onClick={handleSendMessageConfirm}>
        Send
      </Button>
    )}
    <Button color="secondary" onClick={closeOverlay}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>



    </>
  );
};

export default ContactListPage;
