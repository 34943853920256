import React, { useEffect, useState } from "react";
import { Icon, Button, Col, Row } from "../../../components/Component";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import PuffLoader from "react-spinners/PuffLoader";

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" style={{ width: "20px", marginRight: "10px" }} />
    {props.data.label}
  </components.Option>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <img src={props.data.icon} alt="s-logo" className="selected-logo" style={{ width: "20px", marginRight: "10px" }} />
    {children}
  </components.SingleValue>
);

const CompanyListModels = ({ view, modalType, formData, setFormData, onFormCancel, onSubmit, loading }) => {
  const [passState, setPassState] = useState(false);
  const [confirmPassState, setConfirmPassState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
//console.log("countries",countries);
//console.log("selected",selectedCountry);


  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch("password");

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);
//console.log(formData);

  // const blockInvalidChars = (e) => {
  //   const invalidChars = ["-", "e", "E", ".", "+"];
  //   if (invalidChars.includes(e.key)) {
  //     e.preventDefault();
  //   }
  // };

  // const limitInputLength = (e, maxLength) => {
  //   if (e.target.value.length >= maxLength) {
  //     e.preventDefault();
  //   }
  // };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries
        
          .filter((country) => country.id === 101 || country.id === 231)
          .map((country) => ({
            value: country.shortname,
            label: "+" + country.phonecode,
            id: country.id,
            icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
          }));
          //console.log(countriesData);
        
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]); // Default to the first country
        setFormData({ ...formData, country: countriesData[0].id });
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);
  
  // The rest of the code...
  

  // This will send the +91 to backend
  // const handleCountryChange = (value) => {
  //   setSelectedCountry(value);
  //   setFormData({ ...formData, country: value.label });
  // };

  // This will send the 91 to backend
  const handleCountryChange = (value) => {
    const numericCountryCode = value.label.replace("+", ""); // Remove the "+" symbol
    setSelectedCountry(value);
    setFormData({ ...formData, country: numericCountryCode }); 
  };

  const validatePhoneNumber = (value) => {
    const regex = /^\d+$/;
    return regex.test(value) || "Number must contain only digits";
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (checked) => {
    setFormData((prev) => ({
      ...prev,
      verification: checked ? "1" : "0",
    }));
    setIsChecked(true); 
  };
  
  useEffect(() => {
      //console.log("type",typeof(formData.verification));
      setIsChecked(formData.verification === "1");
  }, [formData.verification]); 
  return (
    <Modal isOpen={view} toggle={onFormCancel} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Add Information" : "Update Information"}</h5>
          <div className="mt-4">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="frm_username">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User Name"
                      {...register("frm_username", {
                        required: "This field is required",

                        minLength: {
                          value: 3,
                          message: "Please enter at least 3 characters",
                        },
                      })}
                      maxLength={50}
                      value={formData.frm_username}
                      onChange={(e) => setFormData({ ...formData, frm_username: e.target.value })}
                    />
                    {errors.frm_username && <span className="invalid">{errors.frm_username.message}</span>}
                  </div>
                </Col>
                {formData.role_id == 2 && (
  <Col size="12">
    <div className="form-group">
      <label className="form-label" htmlFor="company_name">
        Company Name
      </label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Company Name"
        {...register("company_name", {
          required:"This field is required",  
        })}
        maxLength={50}
        value={formData.company_name}
        onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
      />
      {errors.company_name && <span className="invalid">{errors.company_name.message}</span>}
    </div>
  </Col>
)}

{formData.role_id != 2 && (
  <Col size="12">
    <div className="form-group">
      <label className="form-label" htmlFor="company_name">
        Company Name
      </label>
      <input
        type="text"
        className="form-control"
        placeholder="Enter Company Name"
        maxLength={50}
        value={formData.company_name}
        onChange={(e) => setFormData({ ...formData, company_name: e.target.value })}
      />
      {errors.company_name && <span className="invalid">{errors.company_name.message}</span>}
    </div>
  </Col>
)}

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="country">
                      Country Code
                    </label>
                    {/* <input
                      type="number"
                      className="form-control"
                      placeholder="Enter Country Code"
                      {...register("country", {
                        // required: "This field is required",
                        maxLength: {
                          value: 4,
                          message: "Phone number cannot exceed 4 digits",
                        },
                        validate: validatePhoneNumber,
                      })}
                      value={formData.country}
                      onChange={(e) => setFormData({ ...formData, country: parseInt(e.target.value) || "" })}
                    /> */}
                    <Select
                      className="w-100"
                      value={selectedCountry}
                      // value={formData.country}
                      options={countries}
                      onChange={handleCountryChange}
                      // onChange={(e) => setFormData({ ...formData, country: parseInt(e.target.value) || "" })}
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          color: state.isSelected ? "white" : "black",
                          backgroundColor: state.isSelected ? "#007bff" : "white",
                          display: "flex",
                          alignItems: "center",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          display: "flex",
                          alignItems: "center",
                        }),
                      }}
                      components={{ Option, SingleValue }}
                    />
                     {errors.country && <p className="invalid">{errors.country.message}</p>}
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="phone_number">
                      Contact Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      placeholder="Enter Contact Number"
                      {...register("phone_number", {
                        required:"This field is required",  
                        minLength: {
                          value: 10,
                          message: "Phone number must be at least 10 digits", 
                        },
                        maxLength: {
                          value: 16,
                          message: "Phone number cannot exceed 16 digits",
                        },
                        validate: validatePhoneNumber,
                        // required: "This field is required",
                      })}
                      value={formData.phone_number}
                      minLength={10}
                      onChange={(e) => setFormData({ ...formData, phone_number: parseInt(e.target.value) || "" })}
                    />
                    {errors.phone_number && <p className="invalid">{errors.phone_number.message}</p>}
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email"
                      {...register("email", {
                        required: "This is required",
                        pattern: {
                          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                          message: "Enter a valid email address",
                        },
                        maxLength: {
                          value: 255,
                          message: "Email must not exceed 255 characters",
                        },
                      })}
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    {errors.email && <span className="invalid">{errors.email.message}</span>}
                  </div>
                </Col>
                <Col md="6">
                  <div className="form-group">
                  <label className="form-label" htmlFor="role">
                       Role
                  </label>
                  <select
                   id="role"
                   name="role"
                   value={formData.role_id} 
                   onChange={(e) =>
                         setFormData({ ...formData, role_id: e.target.value })
                   } 
                   className="form-control"
                  >
                   <option value="3">Client</option>
                   <option value="2">DJ</option>
                 </select>
                </div>
               </Col>

                <Col md="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="address_1">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Address"
                      {...register("address_1", {
                        required: "This is required",})}
                      maxLength={255}
                      value={formData.address_1}
                      onChange={(e) => setFormData({ ...formData, address_1: e.target.value })}
                    />
                    {errors.address_1 && <span className="invalid">{errors.address_1.message}</span>}
                  </div>
                </Col>
                <>
                <Col md="6">
  <div className="form-group">
    <label className="form-label" htmlFor="password">
      {modalType === "edit" ? "Set a new password (optional)" : "Password"}
    </label>
    <div className="form-control-wrap">
      <input
        type={passState ? "text" : "password"}
        className="form-control"
        id="password"
        placeholder="Enter Password"
        {...register("password", {
          required: 
            modalType === "add" 
              ? "Password is required"
              : false, 
          validate: value => {
            
            if (value) {
              const isValid = /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value);
              return isValid || "Enter a strong password (e.g., Abc@1234)";
            }
            return true; 
          },
          maxLength: {
            value: 12,
            message: "Password must not exceed 12 characters",
          },
        })}
        value={formData.password || ""} 
        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
      />
      <a
        href="#password"
        onClick={(ev) => {
          ev.preventDefault();
          setPassState(!passState);
        }}
        className={`form-icon lg form-icon-right passcode-switch h-100 ${
          passState ? "is-hidden" : "is-shown"
        }`}
      >
        <Icon name="eye" className="passcode-icon icon-show"></Icon>
        <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
      </a>
    </div>
    {errors.password && (
      <span className="invalid" style={{ color: "#e85347", fontSize: "11px", fontStyle: "italic" }}>
        {errors.password.message}
      </span>
    )}
  </div>
</Col>



  {/* <Col md="6">
    <div className="form-group">
      <label className="form-label" htmlFor="confirmPassword">
        Confirm Password
      </label>
      <div className="form-control-wrap">
        <a
          href="#password"
          onClick={(ev) => {
            ev.preventDefault();
            setConfirmPassState(!confirmPassState);
          }}
          className={`form-icon lg form-icon-right passcode-switch h-100 ${
            confirmPassState ? "is-hidden" : "is-shown"
          }`}
        >
          <Icon name="eye" className="passcode-icon icon-show"></Icon>
          <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
        </a>
        <input
          type={confirmPassState ? "text" : "password"}
          className="form-control"
          id="confirmPassword"
          placeholder="Enter Confirm Password"
          {...register("confirmPassword", {
            required: formData.password ? "Confirm password is required" : false,
            validate: (value) =>
              value === formData.password || "Passwords do not match",
            maxLength: {
              value: 12,
              message: "Password must not exceed 12 characters",
            },
          })}
          value={formData.confirmPassword || ""}
          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
        />
      </div>
      {errors.confirmPassword && (
        <span className="invalid" style={{ color: "#e85347", fontSize: "11px", fontStyle: "italic" }}>
          {errors.confirmPassword.message}
        </span>
      )}
    </div>
  </Col> */}
</>         
                {
                  modalType === "add" && (
                    <Col size="12" className="d-flex justify-center">
                  <Button color="primary" type="submit" disabled={loading}>
                    <Icon className="plus"></Icon>
                    <span className="mx-auto px-0">
                       Add Information
                    </span>
                    {loading && <PuffLoader speedMultiplier="2" size="30px" color={"#39ff00"} />}
                  </Button>
                </Col>
                  )
                }
                {
                  modalType === "edit" && (
                    <Col size="12" className="d-flex justify-center flex-column flex-md-row">
                       <div className="border pe-2 rounded me-md-2 mb-md-0 mb-2 d-flex align-items-center py-1  py-md-0 ">
      <div className="form-group ">
        <div
          className="form-check d-flex align-items-center ps-0"
          style={{ cursor: "pointer" }}
         
        >
           <input
             type="checkbox"
             id="verifyUser"
             checked={isChecked}
            
             //unchecked={ischecked === 1}
             onChange={(e) => handleChange(e.target.checked)}
             className="form-check-input mx-1 mt-0"
             style={{ width: "20px", height: "20px", cursor: "pointer" }}
          />
          <label
            className="form-check-label ms-1 pt-4"
            style={{ cursor: "pointer" }}
            htmlFor="verifyUser"
          >
            Verify User
          </label>
        </div>
      </div>
    </div>
                    <Button color="primary" type="submit" disabled={loading}>
                    <Icon className="plus"></Icon>
                    <span className="mx-auto px-0">
                       Update Information
                    </span>
                    {loading && <PuffLoader speedMultiplier="2" size="30px" color={"#39ff00"} />}
                  </Button>
                </Col>
                  )
                }
              </Row>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CompanyListModels;
