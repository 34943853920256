import React, { useState, useEffect } from "react";
import "./PaymentGateway.css";
import { NavLink, Nav, NavItem, Row, Col, Button } from "reactstrap";
import classnames from "classnames";
import { BlockTitle } from "../../components/Component";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

export default function PaymentGateway() {
  const [activesTabs, setactivesTabs] = useState("Stripe");
  // const [checkboxStatus, setCheckboxStatus] = useState(0);
  // const [checkboxStatus, setCheckboxStatus] = useState(() => {
  //   return JSON.parse(localStorage.getItem("checkboxStatus")) || { Stripe: 0, Paypal: 0, Razorpay: 0 };
  // });
  const [checkboxStatus, setCheckboxStatus] = useState({ Stripe: 0, Paypal: 0, Razorpay: 0 });
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [clientKey, setClientKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentSettingId, setPaymentSettingId] = useState(null);
  const [paymentType, setPaymentType] = useState("Stripe");
  const [paymentSettings, setPaymentSettings] = useState([]);

  const handleTabClick = (tab) => {
    setactivesTabs(tab);
    setPaymentType(tab);
    fetchPaymentSettings(tab); // Fetch settings for the selected tab
  };

  // const handleCheckboxChange = () => {
  //   setCheckboxStatus((prevStatus) => {
  //     const updatedStatus = { ...prevStatus, [activesTabs]: prevStatus[activesTabs] === 0 ? 1 : 0 };
  //     localStorage.setItem("checkboxStatus", JSON.stringify(updatedStatus)); // Save to local storage
  //     console.log("Checkbox status for", activesTabs, "changed to:", updatedStatus[activesTabs]);
  //     return updatedStatus;
  //   });
  // };

  const handleCheckboxChange = () => {
    setCheckboxStatus((prevStatus) => ({
      ...prevStatus,
      [activesTabs]: prevStatus[activesTabs] === 0 ? 1 : 0,
    }));
  };

  const handleEnvironmentChange = (e) => {
    setSelectedEnvironment(e.target.value);
  };

  const handleClientKeyChange = (e) => {
    setClientKey(e.target.value);
  };

  const handleSecretKeyChange = (e) => {
    setSecretKey(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!selectedEnvironment) {
    //   toast.error("Please select an environment.");
    //   return;
    // }

    const paymentData = {
      payment_type: paymentType,
      environment: selectedEnvironment,
      client_key: clientKey,
      secret_key: secretKey,
      action: checkboxStatus[activesTabs] === 1 ? "1" : "0", // Convert to '0' or '1' as a string
      ...(paymentSettingId && { id: paymentSettingId }),
    };

    // Log the data before sending it to the backend
    //console.log("Data being sent to the backend:", paymentData);

    // console.log("Submitting with action (checkboxStatus):", checkboxStatus);
    // console.log("Payment Data:", paymentData);
    const url = `${process.env.REACT_APP_API_URL}api/v1/admin/add-update-payment-setting`;

    try {
      setLoading(true);
      const headers = { authToken: getCookie("authToken"), "Content-Type": "application/json" };
      const response = await fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify(paymentData),
      });

      const result = await response.json();
      //console.log("Result -> ", result);

      if (result?.status) {
        toast.success("Payment settings saved successfully!");
        setPaymentSettingId(result.data.id);
        fetchPaymentSettings(activesTabs); // Refresh settings after save
      } else {
        toast.error(result?.errors?.[0] || "Error occurred");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while processing your request.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!id) return;

    const result = await Swal.fire({
      title: "Are you sure to delete?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      const url = `${process.env.REACT_APP_API_URL}api/v1/admin/delete-payment-setting`;

      try {
        setLoading(true);
        const headers = { authToken: getCookie("authToken"), "Content-Type": "application/json" };
        const response = await fetch(url, {
          method: "POST",
          headers,
          body: JSON.stringify({ id }),
        });

        const result = await response.json();
        if (result?.status) {
          toast.success("Payment setting deleted successfully!");
          setPaymentSettingId(null); // Clear the ID after deletion
          fetchPaymentSettings(activesTabs); // Refresh settings
        } else {
          toast.error(result?.errors?.[0] || "Error occurred while deleting");
        }
      } catch (error) {
        console.error("Error deleting payment setting:", error);
        toast.error("An error occurred while deleting the payment setting.");
      } finally {
        setLoading(false);
      }
    } else {
      toast.info("Deletion cancelled.");
    }
  };

  const fetchPaymentSettings = async (paymentType) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-payment-setting-list`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result?.status) {
        setPaymentSettings(result.data.PaymentSetting);
        //console.log("setPaymentSettings result -> ", result.data.PaymentSetting);

        // Initialize checkboxStatus from the backend response
        const newCheckboxStatus = { Stripe: 0, Paypal: 0, Razorpay: 0 };
        // Set the action for each payment type based on the backend response
        result.data.PaymentSetting.forEach((setting) => {
          newCheckboxStatus[setting.payment_type] = setting.action === "1" ? 1 : 0;
        });
        setCheckboxStatus(newCheckboxStatus); // Update the state based on backend data

        const selectedSetting = result.data.PaymentSetting.find((setting) => setting.payment_type === paymentType);

        if (selectedSetting) {
          setSelectedEnvironment(selectedSetting.environment || "test");
          setClientKey(selectedSetting.client_key);
          setSecretKey(selectedSetting.secret_key);
          setPaymentSettingId(selectedSetting.id);
        } else {
          // Reset fields if no setting is found
          setSelectedEnvironment("test");
          setClientKey("");
          setSecretKey("");
          setPaymentSettingId(null);
        }
      } else {
        toast.error("Failed to fetch payment settings.");
      }
    } catch (error) {
      console.error("Error fetching payment settings:", error);
      toast.error("An error occurred while fetching payment settings.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch payment settings when the component mounts or when the active tab changes
    fetchPaymentSettings(activesTabs);
  }, [activesTabs]); // Dependency array includes activesTabs

  return (
    <>
      <div className="d-flex flex-row align-center justify-between heading">
        <BlockTitle tag="h4">Payment Gateway</BlockTitle>
        <Link to="/site-setting">
          <Button className="toggle" color="primary" disabled={loading} style={{ marginRight: "33px" }}>
            <span>Back</span>
          </Button>
        </Link>
      </div>

      <div className="gateway-container">
        <Nav tabs className="mb-2">
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activesTabs === "Stripe" })}
              onClick={() => handleTabClick("Stripe")}
            >
              <span className="fs-6">Pay with Card</span>
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activesTabs === "Paypal" })}
              onClick={() => handleTabClick("Paypal")}
            >
              <span className="fs-6">Paypal</span>
            </NavLink>
          </NavItem>
          <NavItem style={{ cursor: "pointer" }}>
            <NavLink
              className={classnames({ active: activesTabs === "Razorpay" })}
              onClick={() => handleTabClick("Razorpay")}
            >
              <span className="fs-6">Razorpay</span>
            </NavLink>
          </NavItem>
        </Nav>

        <div className="checkbox-section d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <input
              type="checkbox"
              checked={checkboxStatus[activesTabs] === 1}
              onChange={handleCheckboxChange}
              style={{ cursor: "pointer" }}
            />
            <label className="checkbox-label">Pay with {activesTabs} Settings</label>
          </div>

          {/* Render Save button here when checkbox is off */}
          {checkboxStatus[activesTabs] === 0 && (
            <Button type="submit" color="primary" className="btn-pg" onClick={handleSubmit} disabled={loading}>
              Save
            </Button>
          )}
        </div>

        {checkboxStatus[activesTabs] === 1 && (
          <form onSubmit={handleSubmit}>
            <div className="form">
              <Row className="align-items-center g-2 mb-4">
                <Col xs="12" sm="7">
                  <div className="form-label-pg pt-4 pb-1">
                    <label htmlFor="environment">Select Environment</label>
                  </div>
                  <select
                    name="environment"
                    value={selectedEnvironment}
                    onChange={handleEnvironmentChange}
                    className="form-control form-control-pg rounded"
                  >
                    <option value="sandbox">Sandbox</option>
                    <option value="live">Live</option>
                  </select>
                </Col>
              </Row>

              <Row className="align-items-center g-2 mb-4">
                <Col xs="12" sm="6">
                  <div className="form-label-csk pb-1">
                    <label htmlFor="clientKey">Client Key</label>
                  </div>
                  <input
                    type="text"
                    name="clientKey"
                    value={clientKey}
                    onChange={handleClientKeyChange}
                    placeholder="Enter Client Key"
                    required
                    className="form-control form-control-csk rounded p-2"
                    style={{
                      height: "40px",
                      width: "97%",
                      padding: "8px",
                      paddingLeft: "20px",
                      fontSize: "14px",
                    }}
                  />
                </Col>

                <Col xs="12" sm="6">
                  <div className="form-label-csk pb-1">
                    <label htmlFor="secretKey">Secret Key</label>
                  </div>
                  <input
                    type="text"
                    name="secretKey"
                    value={secretKey}
                    onChange={handleSecretKeyChange}
                    placeholder="Enter Secret Key"
                    required
                    className="form-control form-control-csk rounded p-2"
                    style={{
                      height: "40px",
                      width: "97%",
                      padding: "8px",
                      paddingLeft: "20px",
                      fontSize: "14px",
                    }}
                  />
                </Col>
              </Row>

              <div className="text-end">
                <Button type="submit" color="primary" className="btn-pg">
                  {loading ? "Loading..." : "Save"}
                </Button>
                {paymentSettingId && (
                  <Button color="danger" className="btn-pg ms-2" onClick={() => handleDelete(paymentSettingId)}>
                    Delete
                  </Button>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
}
