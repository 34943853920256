import React, { useEffect, useState } from "react";
import Icon from "../../../../components/icon/Icon";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { getNotifications } from "../../../../http/get/getApi";
import { useQuery, useQueryClient } from "react-query";
import { useThemeUpdate } from "../../../../layout/provider/Theme";
import "./notification.css"
import { toast } from "react-toastify";
const NotificationItem = ({ text, time, id, module}) => {
  const date = new Date(time);

  const notificationDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()}`;

  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;
  const notificationTime = `${formattedHours}:${minutes} ${ampm}`;

  return (
    <div className="nk-notification-item lg" key={id} id={id}>
      <div className="nk-notification-icon">
      <Icon
          name={
            module === "1" ? "user-add" : module === "2" ? "sign-usd " : module === "3" ? "calendar-booking" : "bell"
          }
        />

      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="d-flex justify-content-between">
          <div className="nk-notification-time">{notificationDate}</div>
          <div className="nk-notification-time">{notificationTime}</div>
        </div>
        
      </div>
    </div>
  );
};

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(true);
  const [seen,setSeen]=useState("");
  const [limit, setLimit] = useState(3);
  const [lastNotificationCheck, setLastNotificationCheck] = useState(
    localStorage.getItem("lastNotificationCheck") || Date.now()
  );
  const queryClient = useQueryClient();

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => {
      const newState = !prevState;
      if (!newState) {
        setLimit(3);
      }
      return newState;
    });
  };

  const {
    data: notificationsData,
    isLoading: notificationsLoading,
    isError: notificationsError,
  } = useQuery({
    queryKey: ["get-admin-notifications", limit],
    queryFn: () => getNotifications({ limit: limit, seen: seen}),
  });

  useEffect(() => {
    localStorage.setItem("lastNotificationCheck", lastNotificationCheck);
  }, [lastNotificationCheck]);

  useEffect(() => {
    if (notificationsData?.data?.length > 0) {
      const hasNewNotifications = notificationsData.data.some(
        (notification) =>
          new Date(notification.created_at).getTime() > lastNotificationCheck
      );
      setHasUnread(hasNewNotifications);
    } else {
      setHasUnread(false);
    }
  }, [notificationsData, lastNotificationCheck]);

  const handleMarked = (e) => {
    e.preventDefault();
    setHasUnread(false);
    setSeen("1")
    toast.success('Notification Status Changed')
    setLastNotificationCheck(Date.now());
  };

  const handleViewAll = (e) => {
    e.preventDefault();
    setLimit(10);
    queryClient.invalidateQueries("get-admin-notifications");
  };

  //console.log("notificationsData:",notificationsData);
  

  return (
    <Dropdown
      isOpen={open}
      className="user-dropdown"
      style={{ margin: 0 }}
      toggle={toggle}
    >
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="position-relative">
          <Icon name="bell" className="text-dark" />
          {hasUnread && (
            <span
              className="position-absolute bg-danger border border-light rounded-circle d-flex align-items-center justify-content-center text-white"
              style={{
                top: "-3.5px",
                right: "-0.4rem",
                width: "1.2rem",
                height: "1.2rem",
                fontSize: "0.6rem",
                lineHeight: "1rem",
              }}
            >
              {notificationsData?.seen_count}
            </span>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          <a href="#markasread" onClick={handleMarked}>
            Mark All as Read
          </a>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification">
            {notificationsLoading ? (
              <div className="d-flex justify-content-center">Loading...</div>
            ) : hasUnread &&
              notificationsData &&
              notificationsData?.data?.length > 0 ? (
                notificationsData?.data?.map((item) => (
                  <div className="notification-item-wrapper" key={item.id}>
                    <NotificationItem
                      id={item.id}
                      text={item.description}
                      time={item.created_at}
                      module={item.module}
                      link={item?.event_id}
                    />
                  </div>
              ))
            ) : (
              <div className="d-flex justify-content-center">
                No notifications available
              </div>
            )}
          </div>
          {notificationsData?.data?.length >= limit && (
            <div className="dropdown-foot">
              <a href="#viewall" onClick={handleViewAll}>
                View All
              </a>
            </div>
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
