import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Row} from "reactstrap";
import { DataTablePagination, TooltipComponent } from "../../components/Component";
import { ShimmerTable } from "react-shimmer-effects";
import exportFromJSON from "export-from-json";

const Export = ({ data }) => {
    const [modal, setModal] = useState(false);
  
    useEffect(() => {
      if (modal) {
        setTimeout(() => setModal(false), 2000);
      }
    }, [modal]);
  
    const fileName = "user-data";
  
    const exportCSV = () => {
      const exportType = exportFromJSON.types.csv;
      exportFromJSON({ data, fileName, exportType });
    };
  
    const exportExcel = () => {
      const exportType = exportFromJSON.types.xls;
      exportFromJSON({ data, fileName, exportType });
    };
  
    const copyToClipboard = () => {
      setModal(true);
    };
  
    return (
      <>
        <div className="dt-export-buttons d-flex align-center">
          <div className="dt-export-title d-none d-md-inline-block">Export</div>
          <TooltipComponent id={"exportData"} text="Export in Excel Format" direction="top" />
          <div className="dt-buttons btn-group flex-wrap" id="exportData">
            {/* <CopyToClipboard text={JSON.stringify(data)}>
              <Button className="buttons-copy buttons-html5" onClick={copyToClipboard}>
                <span>Copy</span>
              </Button>
            </CopyToClipboard>{" "} */}
            {/* <button className="btn btn-secondary buttons-csv buttons-html5" type="button" onClick={exportCSV}>
              <span>CSV</span>
            </button>{" "} */}
            <button className="btn btn-secondary buttons-excel buttons-html5" type="button" onClick={exportExcel}>
              <span>Excel</span>
            </button>{" "}
          </div>
        </div>
        {/* <Modal isOpen={modal} className="modal-dialog-centered text-center" size="sm">
          <ModalBody className="text-center m-2">
            <h5>Copied to clipboard</h5>
          </ModalBody>
          <div className="p-3 bg-light">
            <div className="text-center">Copied {data.length} rows to clipboard</div>
          </div>
        </Modal> */}
      </>
    );
  };
  
const CustomizeReactDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  searchBy,
  loading,
  activePage,
  totalRecords,
  pageNo,
  recordLimit,
  setSearch,
  normalSearch,
}) => {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    let defaultData = data;
    if (searchText !== "") {
      defaultData = data.filter((item) => {
        const nameMatch = item.name?.toLowerCase().includes(searchText.toLowerCase());
        const emailMatch = item.email?.toLowerCase().includes(searchText.toLowerCase());
        return nameMatch || emailMatch; 
      });
    }
    setTableData(defaultData);
  }, [data, searchText]);

  const viewChange = () => {
    setMobileView(window.innerWidth < 960 && expandableRows);
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, [expandableRows]);

  return (
    <div className={`dataTables_wrapper dt-bootstrap4 no-footer ${className ? className : ""}`}>
      <Row className={`justify-between g-2 ${actions ? "with-export" : ""}`}>
              <Col className="col-7 text-start" sm="4">
                {/* <div id="DataTables_Table_0_filter" className="dataTables_filter">
                  <label>
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder={`Search by ${searchBy}`}
                      onChange={handleSearchChange}
                    />
                  </label>
                </div> */}
              </Col>
              <Col className="col-5 text-end" sm="8">
                <div className="datatable-filter">
                  <div className="d-flex justify-content-end g-2">
                    {actions && <Export data={data} />}
                    <div className="dataTables_length" id="DataTables_Table_0_length">
                      <label>
                        <span className="d-none d-sm-inline-block">Show</span>
                        <div className="form-control-select">
                        <select
                      name="DataTables_Table_0_length"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      onChange={(e) => setRowsPerPage(e.target.value)}
                      value={rowsPerPageS}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
      {loading ? (
        <div className="p-1">
          <ShimmerTable row={5} />
        </div>
      ) : (
        <DataTable
          data={tableData && tableData.length > 0 ? tableData : []}
          columns={columns}
          className={className}
          selectableRows={selectableRows}
          expandableRows={mobileView}
          noDataComponent={<div className="p-2">There are no records found</div>}
          sortIcon={
            <div>
              <span>&darr;</span>
              <span>&uarr;</span>
            </div>
          }
          pagination={pagination}
          paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => {
            const ChangePage = (payload) => {
              if (pageNo) pageNo(payload - 1);
              onChangePage(payload);
            };

            const ChangeRowsPerPage = (payload) => {
              if (recordLimit) recordLimit(payload);
              onChangeRowsPerPage(payload);
            };
            return (
              <DataTablePagination
                customItemPerPage={rowsPerPageS}
                itemPerPage={rowsPerPage}
                totalItems={totalRecords || rowCount}
                paginate={ChangePage}
                currentPage={parseInt(activePage) + 1 || currentPage}
                onChangeRowsPerPage={ChangeRowsPerPage}
                setRowsPerPage={setRowsPerPage}
                changePage={pageNo}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default CustomizeReactDataTable;
