import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Col, Row} from "reactstrap";
import { DataTablePagination } from "../../components/Component";
import { ShimmerTable } from "react-shimmer-effects";

const CustomizeReactDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  searchBy,
  loading,
  activePage,
  totalRecords,
  pageNo,
  recordLimit,
  setSearch,
  normalSearch,
}) => {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPageS, setRowsPerPage] = useState(10);
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    let defaultData = data;
    if (searchText !== "") {
      defaultData = data.filter((item) => {
        const nameMatch = item.name?.toLowerCase().includes(searchText.toLowerCase());
        const emailMatch = item.email?.toLowerCase().includes(searchText.toLowerCase());
        return nameMatch || emailMatch; 
      });
    }
    setTableData(defaultData);
  }, [data, searchText]);

  const viewChange = () => {
    setMobileView(window.innerWidth < 960 && expandableRows);
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, [expandableRows]);

  return (
    <div className={`dataTables_wrapper dt-bootstrap4 no-footer ${className ? className : ""}`}>
      <Row className={`justify-between g-2 ${actions ? "with-export" : ""}`}>
        <Col className="col-7 text-start" sm="4">
          <div id="DataTables_Table_0_filter" className="dataTables_filter">
            <label>
            <input
                type="search"
                className="form-control form-control-sm w-250px border-2"
                placeholder="Search..."
                onChange={(ev) => {
                  setSearchText(ev.target.value);
                  if (!normalSearch) {
                    setTimeout(() => {
                      // pageNo(1);
                     // setSearch(ev.target.value);
                    }, 500);
                  }
                }}
              />
            </label>
          </div>
        </Col>
        <Col className="col-5 text-end" sm="8">
          <div className="datatable-filter">
            <div className="d-flex justify-content-end g-2">
              {/* {actions && <Export data={data} />} */}
              <div className="dataTables_length" id="DataTables_Table_0_length">
                <label>
                  <span className="d-none d-sm-inline-block">Show</span>
                  <div className="form-control-select">
                    <select
                      name="DataTables_Table_0_length"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      onChange={(e) => setRowsPerPage(e.target.value)}
                      value={rowsPerPageS}
                    >
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {loading ? (
        <div className="p-1">
          <ShimmerTable row={5} />
        </div>
      ) : (
        <DataTable
          data={tableData && tableData.length > 0 ? tableData : []}
          columns={columns}
          className={className}
          selectableRows={selectableRows}
          expandableRows={mobileView}
          noDataComponent={<div className="p-2">There are no records found</div>}
          sortIcon={
            <div>
              <span>&darr;</span>
              <span>&uarr;</span>
            </div>
          }
          pagination={pagination}
          paginationComponent={({ currentPage, rowsPerPage, rowCount, onChangePage, onChangeRowsPerPage }) => {
            const ChangePage = (payload) => {
              if (pageNo) pageNo(payload - 1);
              onChangePage(payload);
            };

            const ChangeRowsPerPage = (payload) => {
              if (recordLimit) recordLimit(payload);
              onChangeRowsPerPage(payload);
            };
            return (
              <DataTablePagination
                customItemPerPage={rowsPerPageS}
                itemPerPage={rowsPerPage}
                totalItems={totalRecords || rowCount}
                paginate={ChangePage}
                currentPage={parseInt(activePage) + 1 || currentPage}
                onChangeRowsPerPage={ChangeRowsPerPage}
                setRowsPerPage={setRowsPerPage}
                changePage={pageNo}
              />
            );
          }}
        />
      )}
    </div>
  );
};

export default CustomizeReactDataTable;
