import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  ProjectCard,
  UserAvatar,
  Col,
} from "../../../components/Component";
import { MakeApiCall, findUpper, getCookie } from "../../../utils/Utils";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Badge } from "reactstrap";
import FormModal from "./FormModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Switch from "react-switch";

const ProjectCardPage = () => {
  const toggleLg = () => setModalLg(!modalLg);
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };
  const [modalLg, setModalLg] = useState(false);
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    add: false,
    edit: false,
  });
  const [, setEditedId] = useState();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //const [itemPerPage] = useState(6);
  const [loading, setLoading] = useState(false);
  const [monthly_action, setMonthlyAction] = useState(1);
  const [yearly_action, setYearlyAction] = useState(1);
  // console.log(monthly_action);
  // console.log(yearly_action);
  
  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = {
        monthly_action: monthly_action,
        yearly_action: yearly_action,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/get-new-subscription-plan`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken"),
          },
          body: JSON.stringify(payload),
        
          
        }
      );
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      //console.log("response", responseData);
  
      if (Array.isArray(responseData?.data)) {
        setData(responseData?.data);
      } else {
        console.error("Expected an array, got", responseData?.data);
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [monthly_action, yearly_action]);
  
  const deleteData = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure you want to delete the plan?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("id", id);
  
          const response = await MakeApiCall(`api/v1/admin/delete-plan`, formData);
  
          if (response.status === true || response.type === "success") {
            Swal.fire(
              "Deleted!",
              "Your plan has been successfully deleted.",
              "success"
            );
  
            setData((prevData) => prevData.filter((item) => item.id !== id));
          } else {
            Swal.fire(
              "Error",
              response.message || "Failed to delete the plan.",
              "error"
            );
          }
        }
      });
    } catch (error) {
      console.error("Error deleting plan:", error);
      Swal.fire("Error", "An unexpected error occurred.", "error");
    }
  };
  
  
  const [formData, setFormData] = useState({
      name:" ",
      monthly_price: 0,
      yearly_price:0,
      monthly_discount:0,
      yearly_discount:0,
      monthly_discount_price:0,
      yearly_discount_price:0,
      business_description:"",
      photos:0,
      videos:0,
      youtube_link:0,
      mixes:0,
      social_media_urls:0,
      service_list:'1',
      //customize_contact_form:0,
      go_live_streaming:"",
      monthly_duration:'m',
      yearly_duration:'y',
      monthly_action:1,
      yearly_action:1,
      id:'',
  });
  const [editFormData, setEditFormData] = useState({
    name:" ",
      monthly_price:0,
      yearly_price:0,
      monthly_discount:0,
      yearly_discount:0,
      monthly_discount_price:0,
      yearly_discount_price:0,
      business_description:"",
      photos:0,
      videos:0,
      youtube_link:0,
      mixes:0,
      social_media_urls:0,
      service_list:'1',
      //customize_contact_form:0,
      go_live_streaming:"",
      monthly_duration:'m',
      yearly_duration:'y',
      monthly_action:1,
      yearly_action:1,
      id:'',
  });  


  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  // Function to reset the form
  const resetForm = () => {
    setFormData({
      name:" ",
      monthly_price:0,
      yearly_price:0,
      monthly_discount:0,
      yearly_discount:0,
      monthly_discount_price:0,
      yearly_discount_price:0,
      business_description:"",
      photos:0,
      videos:0,
      youtube_link:0,
      mixes:0,
      social_media_urls:0,
      service_list:'1',
      //customize_contact_form:0,
      go_live_streaming:"",
      monthly_duration:'m',
      yearly_duration:'y',
      monthly_action:1,
      yearly_action:1,
      id:'',
    });
  };
  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };
  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };
  const onFormSubmit = async (sData) => {
    setLoading(true);
    const {
      name,
      monthly_price,
      yearly_price,
      monthly_discount,
      yearly_discount,
      monthly_discount_price,
      yearly_discount_price,
      business_description,
      photos,
      videos,
      youtube_link,
      mixes,
      social_media_urls,
      service_list,
      //customize_contact_form,
      go_live_streaming,
      monthly_duration,
      yearly_duration,
      monthly_action,
      yearly_action,
      id,
    } = sData;
    if (!name.trim()) {
      toast.error("Plan Name is required");
      setLoading(false);
      return; 
    }
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append('monthly_price',monthly_price);
      formData.append('yearly_price',yearly_price);
      formData.append('monthly_discount',monthly_discount);
      formData.append('yearly_discount',yearly_discount);
      formData.append('monthly_discount_price',monthly_discount_price);
      formData.append('yearly_discount_price',yearly_discount_price)
      formData.append("business_description", business_description);
      formData.append("photos", photos);
      formData.append("videos", videos);
      formData.append("youtube_link",youtube_link);
      formData.append("mixes", mixes);
      formData.append("social_media_urls", social_media_urls);
      formData.append("service_list", service_list);
      // formData.append("customize_contact_form",  customize_contact_form);
      formData.append("go_live_streaming", go_live_streaming);
      formData.append("monthly_duration", monthly_duration);
      formData.append("yearly_duration", yearly_duration);
      formData.append('monthly_action',monthly_action);
      formData.append('yearly_action',yearly_action);
      formData.append('id',id)
      const planData = await MakeApiCall(`api/v1/admin/add-update-subscription-plan`, formData);
      //console.log(planData);
            
      if (planData && planData.status) {
        const submittedData = planData.data;
        setData((data) => [submittedData, ...data]);
        toast.success("Plan added successfully");
        fetchData();
      } else {
        setLoading(false);
        //console.log("planUser data is invalid or missing required properties");
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
      resetForm();
      setModal({ add: false });
    }
  };
  const onEditSubmit = async (sData) => {
    setLoading(true);
    const {
      name,
      monthly_price,
      yearly_price,
      monthly_discount,
      yearly_discount,
      monthly_discount_price,
      yearly_discount_price,
      business_description,
      photos,
      videos,
      youtube_link,
      mixes,
      social_media_urls,
      service_list,
      //customize_contact_form,
      go_live_streaming,
      monthly_duration,
      yearly_duration,
      monthly_action,
      yearly_action,
      id,
    } = sData;

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append('monthly_price',monthly_price);
      formData.append('yearly_price',yearly_price);
      formData.append('monthly_discount',monthly_discount);
      formData.append('yearly_discount',yearly_discount);
      formData.append('monthly_discount_price',monthly_discount_price);
      formData.append('yearly_discount_price',yearly_discount_price)
      formData.append("business_description", business_description);
      formData.append("photos", photos);
      formData.append("videos", videos);
      formData.append("youtube_link",youtube_link);
      formData.append("mixes", mixes);
      formData.append("social_media_urls", social_media_urls);
      formData.append("service_list", service_list);
      // formData.append("customize_contact_form",  customize_contact_form);
      formData.append("go_live_streaming", go_live_streaming);
      formData.append("monthly_duration", monthly_duration);
      formData.append("yearly_duration", yearly_duration);
      formData.append('monthly_action',monthly_action);
      formData.append('yearly_action',yearly_action);
      formData.append('id',id)
      const planData = await MakeApiCall(`api/v1/admin/add-update-subscription-plan`, formData);
      //console.log(planData);
            
      if (planData && planData.status) {
        const submittedData = planData.data;
        setData((data) => [submittedData, ...data]);
        toast.success("Plan added successfully");
        fetchData();
      } else {
        setLoading(false);
        //console.log("planUser data is invalid or missing required properties");
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
      resetForm();
      setModal({ add: false });
    }
  };
  
  const onEditClick = (id) => {
    // data.forEach((item) => {
    const item = data.find((item) => item.id === id);
    if (item) {
      setEditFormData({
        id: item.id,
        name: item.name,
        monthly_price:item.monthly_price,
        yearly_price:item.yearly_price,
        monthly_discount:item.monthly_discount,
        yearly_discount:item.yearly_discount,
        monthly_discount_price:item.monthly_discount_price,
        yearly_discount_price:item.yearly_discount_price,
        business_description:item.business_description,
        photos:item.photos,
        videos:item.videos,
        youtube_link:item.youtube_link,
        mixes:item.mixes,
        social_media_urls:item.social_media_urls,
        service_list:item.service_list,
        go_live_streaming:item.go_live_streaming,
        monthly_duration:item.monthly_duration,
        yearly_duration:item.yearly_duration,
        monthly_action:item.monthly_action,
        yearly_action:item.yearly_action,
      });
      setModal({ edit: true }, { add: false });
      setEditedId(id);
      //console.log(item.monthly_price);
      
    }      
  };
  // Pagination
  const itemPerPage = 6;  
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.length > 0 && data !== undefined ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [selected, setSelected] = useState("monthly"); 

  const handleSelection = (selection) => {
    setSelected(selection);
  
    if (selection === "monthly") {
      setMonthlyAction(1); 
      setYearlyAction(0); 
    } else if (selection === "yearly") {
      setMonthlyAction(0); 
      setYearlyAction(1); 
    }
  };
  const handleActiveInactive = async (item, checked) => {
    const updatedStatus = checked ? 1 : 0;
  
    try {
      const formData = new FormData();
      formData.append("id", item.id);
      formData.append("action", updatedStatus);
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/change-status-subscription-plan`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );
  
      const responseData = await response.json();
  
      if (response.ok && responseData?.status) {
        setData((prevData) =>
          prevData.map((entry) =>
            entry.id === item.id ? { ...entry, action: updatedStatus } : entry
          )
        );
  
        toast.success("Subscription plan status updated successfully");
      } else {
        throw new Error(responseData.message || "Failed to update subscription plan status");
      }
    } catch (error) {
      console.error("Error toggling subscription plan status:", error);
  
      setData((prevData) =>
        prevData.map((entry) =>
          entry.id === item.id ? { ...entry, action: item.action } : entry
        )
      );
  
      toast.error("Error updating status. Please try again.");
    }
  };
  const [isMobile, setIsMobile] = useState(
    window.innerWidth >= 576 && window.innerWidth <= 904
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 576 && window.innerWidth <= 904);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <React.Fragment>
      <Head title="Project Card"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Create Your Plan</BlockTitle>
              {/* <BlockDes className="text-soft">You have total {data?.length || 0} plan</BlockDes> */}
            </BlockHeadContent>
             <BlockHeadContent>
                            <div className="nk-block-tools g-3">
                              <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                              <Button
                               className="toggle btn-icon d-md-none"
                               color="primary"
                               
                              >
                              <Icon name="plus"></Icon>
                              </Button>
                              <Button
                               className="toggle d-none d-md-inline-flex"
                               color="primary"
                               
                              >
                              <Icon name="plus"></Icon>
                              <span>Create New Plan</span>
                             </Button>
                            </li>
                          </div>
                        </BlockHeadContent>
           
          </BlockBetween>
          
      <Row>
      <Col md="12">
      <div className="d-flex justify-content-center">
        <div
          className="btn-group mt-4 position-relative"
          style={{
            width: "200px",
            height: "40px",
            backgroundColor: "#f0f0f0",
            borderRadius: "20px",
            overflow: "hidden",
            
          }}
        >
          <div
            className="toggle-slider position-absolute btn-primary"
            style={{
              width: "100px",
              height: "40px",
              borderRadius: "20px",
              transition: "transform 0.3s ease",
              transform: selected === "yearly" ? "translateX(100px)" : "translateX(0)",
            }}
            
          ></div>

          <button
            type="button"
            className={`btn fw-bold fs-6 d-flex align-items-center justify-content-center position-relative ${
              selected === "monthly" ? "text-white" : "text-secondary"
            }`}
            style={{
              zIndex: 2, 
              width: "50%",
              border: "none",
              background: "none",
              height: "100%",
            }}
            onClick={() => handleSelection("monthly")}
          >
            Monthly
          </button>

          <button
            type="button"
            className={`btn fw-bold fs-6 d-flex align-items-center justify-content-center position-relative ${
              selected === "yearly" ? "text-white" : "text-secondary"
            }`}
            style={{
              zIndex: 2,
              width: "50%",
              border: "none",
              background: "none",
              height: "100%",
            }}
            onClick={() => handleSelection("yearly")}
          >
            Yearly
          </button>
        </div>
        </div>
       
      </Col>
      
    </Row>
        </BlockHead>
       
        {loading ? (
        <div className="d-flex flex-wrap ">
          {[...Array(data?.length || 3)].map((_, index) => (
            <div
              key={index}
              className="shimmer-card p-3 m-2 shimmerable"
              style={{
                width: isMobile ? "250px" : "400px",
                height: isMobile ? "400px" : "500px",
                backgroundColor: "#f7f7f7",
                borderRadius: "8px",
                animation: "shimmer 1.5s infinite",
              }}
            >
              <div
                className="shimmer-title mb-3"
                style={{
                  width: "60%",
                  height: "20px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "4px",
                }}
              ></div>
              <div
                className="shimmer-line mb-2"
                style={{
                  width: "100%",
                  height: "14px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "4px",
                }}
              ></div>
              <div
                className="shimmer-line mb-2"
                style={{
                  width: "80%",
                  height: "14px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "4px",
                }}
              ></div>
              <div
                className="shimmer-button mt-4"
                style={{
                  width: "50%",
                  height: "30px",
                  backgroundColor: "#e0e0e0",
                  borderRadius: "6px",
                  margin: "auto",
                }}
              ></div>
              
            </div>
          ))}
        </div>
        ) : data?.length === 0 ? (
          <div className="fs-2 fw-bolder justify-content-center d-flex flex-column h-225px text-center">
            No Plan is available
          </div>
        ) : (

          <Block>
  <Row className="g-gs">
    {currentItems &&
      currentItems.map((item) => {
        return (
          <>
            {/* Monthly Plan Card */}
            {selected === "monthly" && item.monthly_action == "1" && (
              <Col sm="6" lg="4" xxl="3" key={`${item.id}-yearly`}>
                <ProjectCard>
               
                <div className="project-head">
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} />
                            <div className="project-info">
                              <h5 className="title fs-5">{item.name}</h5>
                            </div>
                          </a>
                          <UncontrolledDropdown>
                          <Switch
                            id="circle-switch"
                            checked={item?.action == 1}
                            onChange={(checked) => handleActiveInactive(item, checked)}
                            onColor="#77c96b"
                            offColor="#d3d3d3"
                            onHandleColor="#36ba00"
                            handleDiameter={15}
                            width={40}
                            height={20}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            className="me-2"
                           />
                            <DropdownToggle
                              tag="a"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => onEditClick(item.id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit Plan</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteData(item.id);
                                      // handleAdvanced3()
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Remove Plan</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                  {item?.monthly_discount !== "NaN" &&
                    item?.monthly_discount !== "0" && (
                      <div className="d-flex w-100 justify-end me-3">
                        <Badge color="primary">
                          Save up to {item?.monthly_discount}%
                        </Badge>
                      </div>
                    )}
                  <ul className="team-statistics py-1">
                    <li>
                    <span className="d-flex justify-center">
                       {item.monthly_price !== item.monthly_discount_price ? (
                      <>
                          <span className="fw-medium fs-4 text-body text-decoration-line-through">
                            ${item.monthly_price}
                          </span>
                          {item.monthly_discount_price && (
                          <span className="fw-medium fs-4 text-primary ms-1 shadow px-1">
                              ${parseFloat(item.monthly_discount_price).toFixed(2)}
                          </span>
                        )}
                       </>
                        ) : (
                          <span className="fw-medium fs-4 text-primary">
                            ${parseFloat(item.monthly_discount_price).toFixed(2)}
                           </span>
                          )}
                    </span>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between mt-3">
                          <div className="fs-4 fw-bold">Plan Features & Their Limits</div>
                        </div>
                        <div className="d-flex flex-column py-1">
                          <div className="d-flex justify-content-between my-1">
                            <span>Business Description :</span>
                            <span>{item?.business_description}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Photos :</span>
                            <span>{item?.photos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Upload Video Limit :</span>
                            <span>{item?.videos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Youtube Videos Links :</span>
                            <span>{item?.youtube_link}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Mixers :</span>
                            <span>{item?.mixes}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Social Media URL's :</span>
                            <span>{item?.social_media_urls}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Service Listings :</span>
                            <span>{item?.service_list}</span>
                          </div>
                         
                          <div className="d-flex justify-content-between my-1">
                            <span>Go-Live Streaming :</span>
                            <span>{item?.go_live_streaming}</span>
                          </div>
                        </div>
                </ProjectCard>
              </Col>
            )}

            {/* Yearly Plan Card */}
            {selected === "yearly" && item.yearly_action == "1" && (
              <Col sm="6" lg="4" xxl="3" key={`${item.id}-yearly`}>
                <ProjectCard>
                
                <div className="project-head">
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} />
                            <div className="project-info">
                              <h5 className="title fs-5">{item.name}</h5>
                            </div>
                          </a>
                         
                          <UncontrolledDropdown>
                          <Switch
                             id="circle-switch"
                             checked={item?.action == 1}
                             onChange={(checked) => handleActiveInactive(item, checked)}
                             onColor="#77c96b"
                             offColor="#d3d3d3"
                             onHandleColor="#36ba00"
                             handleDiameter={15}
                             width={40}
                             height={20}
                             checkedIcon={false}
                             uncheckedIcon={false}
                             boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                             activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                             className="me-2"
                            />
                            <DropdownToggle
                              tag="a"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                            >
                              <Icon name="more-v"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => onEditClick(item.id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit Plan</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteData(item.id);
                                      // handleAdvanced3()
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Remove Plan</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                            {/* <Switch
            id="circle-switch"
            checked={item?.action == 1}
            onChange={(e) => handleActiveInactive(item, e.target.checked)}
            onColor="#77c96b"
            offColor="#d3d3d3"
            onHandleColor="#36ba00"
            handleDiameter={15}
            width={40}
            height={20}
            checkedIcon={false}
            uncheckedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            className="ms-3"
          /> */}
                          </UncontrolledDropdown>
                          
                         
                        </div>
                  {item?.yearly_discount !== "NaN" &&
                    item?.yearly_discount !== "0" && (
                      <div className="d-flex w-100 justify-end me-3">
                        <Badge color="primary">
                          Save up to {item?.yearly_discount}%
                        </Badge>
                      </div>
                    )}
                  <ul className="team-statistics py-1">
                    {/* <li>
                      <span className="text-primary fw-medium">Yearly</span>
                      <span className="text-primary fw-medium">
                        Plan Duration
                      </span>
                    </li> */}
                    <li>
                    <span className="d-flex justify-center">
                       {item.yearly_price !== item.yearly_discount_price ? (
                      <>
                          <span className="fw-medium fs-4 text-body text-decoration-line-through">
                            ${item.yearly_price}
                          </span>
                          {item.yearly_discount_price && (
                          <span className="fw-medium fs-4 text-primary ms-1 shadow px-1">
                              ${parseFloat(item.yearly_discount_price).toFixed(2)}
                          </span>
                        )}
                       </>
                        ) : (
                          <span className="fw-medium fs-4 text-primary">
                            ${parseFloat(item.yearly_discount_price).toFixed(2)}
                           </span>
                          )}
                    </span>

                    {/*                       
                      <span className="text-primary fw-medium fs-5">
                        Rate Of Plan
                      </span> */}
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between mt-3">
                          <div className="fs-4 fw-bold">Plan Features & Their Limits</div>
                        </div>
                        <div className="d-flex flex-column py-1">
                          <div className="d-flex justify-content-between my-1">
                            <span>Business Description :</span>
                            <span>{item?.business_description}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Photos :</span>
                            <span>{item?.photos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Upload Video Limit :</span>
                            <span>{item?.videos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Youtube Videos Links :</span>
                            <span>{item?.youtube_link}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Mixers :</span>
                            <span>{item?.mixes}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Social Media URL's :</span>
                            <span>{item?.social_media_urls}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Service Listings :</span>
                            <span>{item?.service_list}</span>
                          </div>
                          {/* <div className="d-flex justify-content-between my-1">
                            <span>Cutomized Contact Form :</span>
                            <span>{item?.customize_contact_form}</span>
                          </div> */}
                          <div className="d-flex justify-content-between my-1">
                            <span>Go-Live Streaming :</span>
                            <span>{item?.go_live_streaming}</span>
                          </div>
                        </div>
                </ProjectCard>
              </Col>
            )}
          </>
        );
      })}
  </Row>
  
</Block>
        )}

        <FormModal
          modal={modal.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
          setMonthlyAction={setMonthlyAction}
          setYearlyAction={setYearlyAction}
      />
        <FormModal
          modal={modal.edit}
          modalType="edit"
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
          setMonthlyAction={setMonthlyAction}
          setYearlyAction={setYearlyAction}
        />
      </Content>
    </React.Fragment>
  );
};
export default ProjectCardPage;
