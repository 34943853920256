import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard,Icon,Button, DataTableRow} from "../../components/Component";
import Content from "../../layout/content/Content";
import { getCookie } from "../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import { toast } from "react-toastify";
import CompanyListModels from "../prebuilt/Company List/CompanyListModels";
import { useLocation, useNavigate } from "react-router";
import { checkLogin, setAuthTokenCookie } from "../../http/post/postApi";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, Row, Col } from "reactstrap";
import PuffLoader from "react-spinners/PuffLoader";
import Swal from "sweetalert2";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const Planpayment = () => {
  const [loadingUser, setLoadingUser] = useState(null);

  const handleLoginClick = async (username, role, verifyEmail) => {
    if (!username) {
      console.error("Username is not defined");
      return;
    }
    if (verifyEmail != 1) {
      toast.error("User is not verified. Please verify the user before login.");
      return;
    }
    setLoadingUser(username);
    try {
      const response = await checkLogin({ username });
      console.log("API Response:", response);
  
      const authToken = response?.data?.authToken;
      const email = response?.data?.email;
      const role = response?.data?.role.id;
  
      if (authToken) {
        setAuthTokenCookie(authToken, username, email, role);
  
        let url;
        if (role === 3) {
          // Role 3: Client
          url = `${process.env.REACT_APP_CLIENT_URL}`;
        } else if (role === 2) {
          // Role 2: DJ
          url = `${process.env.REACT_APP_DJ_DASHBOARD_URL}`;
        } else {
          toast.error("Unknown role. Unable to login.");
          return;
        }
  
        window.location.href = url;
        console.log("Login successful and redirected to:", url);
      } else {
        console.warn("Auth token is missing, login not completed");
        toast.error("Failed to login. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error("Failed to login. Please try again.");
    } finally {
      setLoadingUser(null);
    }
  };
  
  const navigate = useNavigate();
  const [companyData, setCompanyData] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10); 

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [phoneValue, setPhoneValue] = useState();
  const [editPhone, setEditPhone] = useState();
  const [loadingRow, setLoadingRow] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [files, setFiles] = useState([]);
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const handleFilteredData = (filteredLength) => {
    setFilteredDataLength(filteredLength);
  };
  const location = useLocation();
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    if (location.state?.tab) {
      setFilterValue(location.state.tab); // Set from state passed by navigate
    } else {
      const params = new URLSearchParams(location.search);
      const tab = params.get("tab");
      if (tab) {
        setFilterValue(tab); // Set from query parameter
      }
    }
  }, [location.state, location.search]);
    const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", pageNo);
      formData.append("limit", recordLimit);
      formData.append("search_keyword", search);
      formData.append("filter_value", filterValue); 

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/get-companies`,
        {
          method: "POST",
          headers: { authToken: getCookie("authToken") },
          body: formData,
        }
      );

      const responseData = await response.json();
   
      if (responseData?.data) {
        setCompanyData(responseData?.data?.list);
        setTotalRecords(responseData?.data?.totalRecords || 0);
      } else {
        setCompanyData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [pageNo, recordLimit, search, filterValue]); 

  
  const handleFilterChange = (value) => {
    setFilterValue(value);
    setPageNo(0);
  };

  const Status = ({ status }) => {
    const parsedStatus = Number(status);
    
    if (parsedStatus === 1) {
      return (
        <div className="d-flex align-items-center text-success">
          <Icon name="icon ni ni-dot" style={{ fontSize: "30px" }} />
          Verified
        </div>
      );
    }
  
    if (parsedStatus === 0) {
      return (
        <div className="d-flex align-items-center text-danger"style={{whiteSpace:"nowrap"}}>
          <Icon name="icon ni ni-dot" style={{ fontSize: "30px" }} />
          Unverified
        </div>
      );
    }
  
    return (
      <div className="d-flex align-items-center text-warning">
        <Icon name="icon ni ni-alert" style={{ fontSize: "14px" }} />
        Unknown
      </div>
    );
  };
  const Role = ({ role }) => {
    const parsedRole = Number(role);
    
    if (parsedRole === 3) {
      return (
        <div className="ps-3">
          Client
        </div>
      );
    }
  
    if (parsedRole === 2) {
      return (
        <div className="ps-3">
          DJ
        </div>
      );
    }
  
    return (
      <div className="d-flex align-items-center text-warning">
        <Icon name="icon ni ni-alert" style={{ fontSize: "14px" }} />
        Unknown
      </div>
    );
  };
   //Delete
    const deleteData = async (id) => {
     // console.log(id);
      
      try {
  
        const user = companyData.some(user => user.id === id);
        if (!user) {
          Swal.fire("Error!", "User not found in the list.", "error");
          return;
        }
  
        const result = await Swal.fire({
          title: "Are you sure to delete the user information?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
        });
  
        if (result.isConfirmed) {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}api/v1/admin/delete-companies`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authToken: getCookie("authToken"),
              },
              body: JSON.stringify({ id }),
            }
          );
  
          const responseData = await response.json();
  
          if (responseData.status && responseData.message === 'company deleted successfully') {
            setCompanyData((prevData) => prevData.filter((item) => item.id !== id));
            Swal.fire("Deleted!", "User information has been deleted permanently.", "success");
            fetchData()
          } else {
            throw new Error(`Failed to delete user information. Status: ${response.status}`);
          }
        }
      } catch (error) {
        console.error("Error deleting user information:", error);
        Swal.fire("Error!", error.message, "error");
      }
    };

  const dataTableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (pageNo * recordLimit) + index + 1
    },
    {
      name: "Username",
      selector: (row) => row?.username || <NotAvailable />,
    },
    {
      name: "Company Name",
      selector: (row) => row?.company_name || <NotAvailable />,
    },
    {
      name: "Email",
      selector: (row) => row?.email || <NotAvailable />,
    },
    {
      name: "Contact Number",
      selector: (row) => row?.phone_number || <NotAvailable />,
    },
    {
      name: " Verification",
      cell: (row) => <Status status={row?.verify_email} />,
    },
    {
      name: "User Role",
      selector: (row) => <Role role={row?.role_id} /> || <NotAvailable />,
    },
    
    {
      name: "Login",
      cell: (row) => (
        <div className="d-flex justify-center">
          {/* Mobile button */}
          <Button
            className="toggle btn-icon d-md-none"
            color="primary"
            onClick={() => handleLoginClick(row?.username, row?.role_id,row?.verify_email)}
            disabled={!row?.username}
          >
            {loadingUser === row?.id ? (
              <>
                <div className="spinner"></div> {/* Circular Spinner */}
                <span style={{ marginLeft: "10px" }}></span>
              </>
            ) : (
              <Icon name="icon ni ni-user-fill" />
            )}
          </Button>
    
          {/* Desktop button */}
          <Button
  className="toggle d-none d-md-inline-flex me-5"
  color="primary"
  onClick={() => handleLoginClick(row?.username, row?.role_id, row?.verify_email)}
  disabled={!row?.username}
>
  {loadingUser === row?.id ? (
    <>
      <div className="spinner"></div>
      <span style={{ marginLeft: "10px" }}>Login...</span>
    </>
  ) : (
    <span>
      {row?.role_id === 3 ? "Login As Client" : "Login As DJ"}
    </span>
  )}
</Button>

        </div>
      ),
    }
    ,
    
    {
        name: "Actions",
        cell: (row) => (
          <UncontrolledDropdown>
            <DropdownToggle
              tag="a"
              href="#more"
              onClick={(ev) => ev.preventDefault()}
              className="dropdown-toggle btn btn-icon btn-trigger ms-0 ms-md-3"
            >
              <Icon name="more-h" />
            </DropdownToggle>
            <DropdownMenu end style={{ 
  maxHeight: (companyData?.length <= 5 || filteredDataLength <= 5) ? '80px' : 'auto', 
  overflowY: (companyData?.length <= 5 || filteredDataLength <= 5) ? 'auto' : 'visible' 
}}>
              <ul className="link-list-opt no-bdr">
                <li style={{ cursor: "pointer" }}>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onEditClick(row?.id);
                      toggle("edit");
                    }}
                  >
                    <Icon name="edit" />
                    <span>Edit Information</span>
                  </DropdownItem>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <DropdownItem
                    tag="a"
                    onClick={() => navigate(`/company-profile?id=${row?.id}`)}
                  >
                    <Icon name="eye" />
                    <span>View Profile</span>
                  </DropdownItem>
                </li>
                <li style={{ cursor: "pointer" }}>
                  {row?.is_suspend === "1" ? (
                    <DropdownItem
                      className="text-danger"
                      tag="a"
                      onClick={() => {
                        chkSuspend(row?.id, 0); // Activate company
                      }}
                    >
                      {loadingRow && companyId === row?.id ? (
                        <div className="justify-center">
                          <PuffLoader speedMultiplier="2" size="40" color={"#39ff00"} />
                        </div>
                      ) : (
                        <>
                          <Icon name="file-check" className="fs-4" />
                          <span>Activate User</span>
                        </>
                      )}
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      tag="a"
                      onClick={() => {
                        chkSuspend(row?.id, 1); // Suspend company
                      }}
                    >
                      {loadingRow && companyId === row?.id ? (
                        <div className="justify-center">
                          <PuffLoader speedMultiplier="2" size="40" color={"#39ff00"} />
                        </div>
                      ) : (
                        <>
                          <Icon name="file-remove" className="fs-4" />
                          <span>Suspend User</span>
                        </>
                      )}
                    </DropdownItem>
                  )}
                </li>
                <li style={{ cursor: "pointer" }}>
                  <DropdownItem
                    tag="a"
                    onClick={(ev) => {
                      ev.preventDefault();
                      deleteData(row.id);
                    }}
                    
                  >
                    <Icon name="trash" />
                    <span>Delete Information</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
      }
      
  ];
  
  const rowClass = (row) => {
    return row?.is_suspend === "1" ? "bg-danger text-white" : "";
  };
  
  
  const [editId, setEditedId] = useState();

  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
    });
  };
  const [formData, setFormData] = useState({
    frm_username: "",
    email: "",
    company_name: "",
    phone_number: "",
    address_1: "",
    created_at: "",
    password: "",
    confirmPassword: "",
    country: "",
    role_id:"3",
    verification:1
  });
 
  const resetForm = () => {
    setFormData({
      frm_username: "",
      email: "",
      company_name: "",
      phone_number: "",
      address_1: "",
      created_at: "",
      password: "",
      confirmPassword: "",
      country: "",
      role_id:"3",
      verification:"1"
    });
  };
  
  const onFormSubmit = async (form) => {
    setLoading(true);
    const { frm_username, email, phone_number, company_name, address_1, password, country ,role_id} = form;
   
    try {
      const formData = new FormData();
      formData.append("username", frm_username);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("company_name", company_name || " ");
      formData.append("address_1", address_1);
      formData.append("password", password);
      formData.append("country", country);
      formData.append("role_id",role_id);
      const companyData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-companies`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      }).then((res) => res.json());
      
      
      if (companyData && companyData.status) {
        const submittedData = companyData?.data;
        toast.success(`${frm_username} User Information Added Successfully`);
        fetchData();
        resetForm();
        setLoading(false);
        setView({ edit: false });
      } else {
        toast.error(companyData?.errors?.[0]);
        setLoading(false);
        console.log("data is invalid or missing required properties");
      }
    } catch (error) {
      setLoading(false);
      toast.error(companyData?.errors?.[0]);
      console.log("Error fetching user data:", error);
    }
  }
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  const onEditSubmit = async (form) => {
    setLoading(true);
    const { id, frm_username, email, phone_number, company_name, address_1, country,role_id,verification,password} = form;
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("username", frm_username);
      formData.append("email", email);
      formData.append("phone_number", phone_number);
      formData.append("company_name", company_name || " ");
      formData.append("address_1", address_1);
      formData.append("country", country );
      formData.append("role_id",role_id);
      formData.append("verification",verification)
      if (password) {
        formData.append("password", password);
      }
      const companyData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/edit-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      }).then((res) => res.json());
      
      if (companyData && companyData.status === true) {
        const submittedData = companyData?.data;
        //console.log("data",companyData?.data);
        toast.success(`${frm_username} User Information Updated Successfully`);
        fetchData();
        resetForm();
        setView({ edit: false });
        setLoading(false);
      } else {
        if (!country) {
          toast.error("Country code is required");
        }else if(!password){
          toast.error("Password is required");
        } else if(!phone_number){
          toast.error("phone number is required");
        } else if(!frm_username){
          toast.error("username is required");
        } else if(!verification){
          toast.error("verification is required");
        } else if(!address_1){
          toast.error("address is required");
        } else if(!company_name){
          toast.error("company name is required");
        }  else {
          toast.error(companyData.message);
        }
      
        console.log("data is invalid or missing required properties");
        setLoading(false);
      }
    } catch (error) {
      toast.error(companyData.message);
      console.log("Error fetching user data:", error);

      setLoading(false);
    }
  };

  
  const onEditClick = (id) => {
    companyData.forEach((item) => {
      setEditPhone(item.phone_number);
      if (item.id === id) {
        setFormData({
          id: item.id,
          frm_username: item.username,
          email: item.email,
          phone_number: item.phone_number,
          company_name: item.company_name,
          address_1: item.address_1,
          password: item.password || "",
          country: item.country,
          role_id:item.role_id,
          verification:item.verify_email,
          created_at: item.created_at,
        });
      }
    });
    setEditedId(id);
    setFiles([]);
    setView({ add: false, edit: true });
  };
  const chkSuspend = async (id, val) => {
    setLoadingRow(true);
    setCompanyId(id);
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("is_suspend", val);
      const SuspendResult = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/edit-companies`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      });
      if (SuspendResult && SuspendResult.status) {
        toast.success(`User Dashboard Status Updated`);
        fetchData();
        setLoadingRow(false);
        setCompanyId("");
      } else {
        toast.error(SuspendResult.message);
        setLoadingRow(false);
        setCompanyId("");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
      setLoadingRow(false);
      setCompanyId("");
    }
  };

  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between">
                <div>
                <BlockTitle tag="h4">User Details</BlockTitle>
                <div className="tab-container">
                 <button
                  className={`tab-button ${filterValue === "" ? "active" : ""}`}
                  onClick={() => handleFilterChange("")}
                 >
                    All
                 </button>
                 <button
                  className={`tab-button ${filterValue === "1" ? "active" : ""}`}
                  onClick={() => handleFilterChange("1")}
                 >
                   Client
                 </button>
                 <button
                  className={`tab-button ${filterValue === "2" ? "active" : ""}`}
                  onClick={() => handleFilterChange("2")}
                 >
                   DJ
                 </button>

                 {/* Style */}
                 <style>{`
                   .tab-container {
                    display: flex;
                    gap: 10px;
                    margin-bottom: 20px;
                  }
                  .tab-button {
                   padding: 7px 14px;
                   border: none;
                   border-radius: 5px;
                   background-color: #f1f1f1;
                   cursor: pointer;
                  }
                  .tab-button.active {
                   background-color: #77c96b;
                   color: white;
                  }
                   .tab-button:hover {
                   background-color:rgb(132, 205, 121);
                   color:#fff
                  }
                  `}</style>
               </div>
              </div>
                <div>
                <BlockHeadContent>
                <div className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                  <Button
                   className="toggle btn-icon d-md-none"
                   color="primary"
                   onClick={() => {
                   toggle("add");
                  }}
                  >
                  <Icon name="plus"></Icon>
                  </Button>
                  <Button
                   className="toggle d-none d-md-inline-flex"
                   color="primary"
                   onClick={() => {
                   toggle("add");
                   resetForm();
                  }}
                  >
                  <Icon name="plus"></Icon>
                  <span>Add Users</span>
                 </Button>
                </li>
              </div>
            </BlockHeadContent>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <CustomizeReactDataTable
              data={companyData}
              columns={dataTableColumns}
              searchBy={"company_name"}
              pagination
              actions
              loading={loading}
              setSearch={setSearch}
              rowClass={rowClass}
              progressPending={loading}
              pageNo={setPageNo}
              recordLimit={setRecordLimit}
              totalRecords={totalRecords}
             activePage={pageNo || 0}
             onFilteredData={handleFilteredData} 
            />
          </PreviewCard>
        </Block>
        <CompanyListModels
          view={view.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
          setPhoneValue={setPhoneValue}
          phoneValue={phoneValue}
        />
        <CompanyListModels
          view={view.edit}
          modalType="edit"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
          setPhoneValue={setPhoneValue}
          phoneValue={phoneValue}
          editPhone={editPhone}
        />
      </Content>
      
    </>
  );
};

export default Planpayment;
