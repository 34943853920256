import React, { useEffect, useState } from "react";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  //TooltipComponent,
} from "../../components/Component";
import Content from "../../layout/content/Content";
import { formatDate, getCookie} from "../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import { Button } from "reactstrap";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const EventList = () => {
  const [eventData, setEventData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState("");

  const [pageNo, setPageNo] = useState(0); 
  const [recordLimit, setRecordLimit] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0); 
//console.log("pageno",pageNo)

const fetchData = async () => {
  setIsLoading(true);
  try {
    const formData = new FormData();
    formData.append("page", pageNo);
    formData.append("limit", recordLimit);
    formData.append("search", search);
    //console.log('page No.', pageNo);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/admin/get-companies-filter`,
      {
        method: "POST",
        headers: { authToken: getCookie("authToken") },
        body: formData,
      }
    );

    const responseData = await response.json();
    
    if (responseData?.data) {
      setEventData(responseData?.data?.eventData);
      setTotalRecords(responseData?.data?.total || 0);
    } else {
      setEventData([]);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  fetchData();
}, [pageNo, recordLimit, search]);


  const dataTableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (pageNo * recordLimit) + index + 1,
      //selector: (row) => row?.id || <NotAvailable />,
    },
   {
         name: "Company Name",
         selector: (row) =>
           // `${row.first_name || ""} ${row.last_name || ""}`.trim() ||
           `${row?.company_name || ""}`.trim() || <NotAvailable />,
       },
       {
         name: "Address",
         selector: (row) => row?.address_1 || <NotAvailable />,
       },
       {
         name: "Event Name",
         selector: (row) => row.event_name || <NotAvailable />,
       },
       {
         name: "Event Type",
         selector: (row) => row.event_type_title || <NotAvailable />,
       },
       {
         name: "Event Start-Date",
         selector: (row) => formatDate(row.start_date_time) || <NotAvailable />,
       },
       {
         name: "Event End-Date",
         selector: (row) => formatDate(row.end_date_time) || <NotAvailable />,
       },
  ];

  return (
    <>
    <div className="card-inner d-flex justify-content-between">
        <BlockTitle className="mb-0">Event List</BlockTitle>
        <Button className="btn-dim bg-primary text-white px-2 p-0" >
          <Icon name="filter"></Icon>
          <span className="sub-text text-white">Filter</span>
        </Button>
      </div>
      <Content>
        <Block size="lg">
          <PreviewCard>
            <CustomizeReactDataTable
              data={eventData}
              columns={dataTableColumns}
              searchBy={"name"}
              pagination
              actions
              loading={isLoading}
              pageNo={setPageNo}
              recordLimit={setRecordLimit}
              totalRecords={totalRecords}
             activePage={pageNo || 0}
            />
          </PreviewCard>
        </Block>
      </Content>
     
    </>
  );
};

export default EventList;
