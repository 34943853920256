import React, { useState, useEffect } from "react";
import { Icon, PaginationComponent } from "../../../components/Component";
import DataTable from "react-data-table-component";
import {
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  Tooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classnames from "classnames";
import "./ShowRecords.css";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

export default function ShowRecords({ data, handleEdit, handleDelete, type, setType}) {
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("category");
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  
  const toggleTooltip = (id) => {
    setTooltipOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const filterData = () => {
    let displayedData = [];
    setLoading(true);
    setNoRecordsFound(false);

    if (activeTab === "category" && data.Categories) {
      displayedData = data.Categories;
    } else if (activeTab === "services" && data.Services) {
      displayedData = data.Services;
    }

    if (searchText) {
      displayedData = displayedData.filter((item) =>
        item.title.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    if (displayedData.length === 0 && searchText) {
      setNoRecordsFound(true);
    }

    setFilteredData(displayedData);
    setLoading(false);
  };

  useEffect(() => {
	setRowsPerPage(5);
  setCurrentPage(1);
  },[data,type])

  useEffect(() => {
    filterData();
  }, [data, activeTab, searchText]);

  useEffect(() => {
    setActiveTab(type);
  }, [type]);

  useEffect(() => {
    setCurrentPage(1);
  }, [activeTab]);
  
  // Calculate current items for pagination
  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleFilterChange = (newFilter) => {	
    setActiveTab(newFilter);
    setType(newFilter);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");

  const toggleModal = () => setModalOpen(!modalOpen);
  const handleViewDescription = (Description) => {
    setCurrentDescription(Description);
    setModalOpen(true);
  };
  const columns = [
    {
      name: "Sr. No.",
        cell: (row, index) => indexOfFirstItem + index + 1, // Calculate Sr. No., 1 taken coz to start from 1 instead of 0 
        center: true,
    },

    {
      name: "Name",
      selector: (row) => row.title,
      sortable: true,
      center: true,
      cell: (row) => (
        <div
          id={`tooltip-${row.id}`}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
          className="tooltip-row" 
        >
          {row.title}
          {row.title.length > 25 && (
            <Tooltip
              placement="bottom"
              isOpen={tooltipOpen[row.id]}
              target={`tooltip-${row.id}`}
              toggle={() => toggleTooltip(row.id)}
            >
              {row.title}
            </Tooltip>
          )}
        </div>
      ),
    },

    {
      name: "Type",
      selector: () => activeTab,
      center: true,
    },
    {
      name: "Description",
      cell: (row) => (
        <span
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => handleViewDescription(row?.description)}
          className="fw-bold"
        >
          View
        </span>
      ),
      center: true,
    },

    {
      name: "Action",
      cell: (row) => (
        <UncontrolledDropdown className="action-buttons">
          <DropdownToggle tag="a" href="#" onClick={(e) => e.preventDefault()} className="dropdown-toggle btn btn-icon btn-trigger">
            <Icon name="more-h" />
          </DropdownToggle>
          <DropdownMenu end>
            <DropdownItem tag="a" onClick={() => handleEdit(row.id, activeTab)}>
              <Icon name="edit" />
              <span>Edit</span>
            </DropdownItem>
            <DropdownItem tag="a" onClick={() => handleDelete(row.id, activeTab)}>
              <Icon name="trash" />
              <span>Delete</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      center: true,
    },
  ];

  return (
    <div className="records-container">
      <Nav tabs className="mb-2" style={{marginTop:'-10px'}}>
        <NavItem style={{ cursor: "pointer" }}>
          <NavLink
            className={classnames({ active: activeTab === "category" })}
            onClick={() => handleFilterChange("category")}
          >
            <span>Category</span>
          </NavLink>
        </NavItem>
        <NavItem style={{ cursor: "pointer" }}>
          <NavLink
            className={classnames({ active: activeTab === "services" })}
            onClick={() => handleFilterChange("services")}
          >
            <span>Services</span>
          </NavLink>
        </NavItem>
      </Nav>

      <Row className="align-items-center g-2 pb-3">
        <Col xs="12" sm="7">
          <div className="dataTables_filter">
            <label>
              <input
                type="search"
                className="form-control form-control-sm w-100 border-2"
                placeholder="Search by Name"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </label>
          </div>
        </Col>
        <Col xs="12" sm="5" className="text-end">
          <div className="dataTables_length">
            <label className="d-flex justify-content-md-end align-items-center w-100">
              <span className="me-2">Show</span>
              <div className="form-control-select">
                <select
                  className="custom-select custom-select-sm form-control form-control-sm"
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                  value={rowsPerPage}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
            </label>
          </div>
        </Col>
      </Row>

      <div className="table-container " style={{ overflowY: "auto", marginTop:'-15px'}}>
        {loading ? (
          <div className="loading-message">Loading...</div>
        ) : noRecordsFound ? (
          <div className="loading-message m-3">No records found</div>
        ) : (
          <>
            <DataTable
              data={currentItems}
              columns={columns}
              pagination={false}
              noDataComponent={<div className="loading-message">Loading...</div>}
            />
			
          </>
        )}
      </div>
	  <div className="pagination-footer">
		<PaginationComponent
		  itemPerPage={rowsPerPage}
		  totalItems={filteredData.length}
		  paginate={handlePageChange}
		  currentPage={currentPage}
		/>
	  </div>
     {/* View Description */}
     <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Description</ModalHeader>
        <ModalBody className="pb-5">
          <p>{currentDescription}</p>
        </ModalBody>
        <ModalFooter className="py-4" />
      </Modal>

    </div>
  );
}
