import React from "react";
import { Line } from "react-chartjs-2";
import PuffLoader from "react-spinners/PuffLoader";

export const CompanyGraph = ({ filterData }) => {
  if (!filterData) {
    return (
      <div className="d-flex justify-content-center align-center h-100">
        <PuffLoader size={50} color={"#39ff00"} />
      </div>
    );
  }

  const Overview = {
    labels: filterData.labels,
    dataUnit: filterData.dataUnit || "Users", 
    datasets: [
      {
        label: "Company Registrations Overview",
        backgroundColor: "rgba(10,120,89,0.2)",
        borderColor: "#798bff",
        pointHoverBorderColor: "#798bff",
        pointHoverBackgroundColor: "#fff",
        fill: true,
        borderWidth: 2,
        lineTension: 0.1,
        data: filterData.datasets?.[0]?.data || [],
      },
    ],
  };

  return (
    <Line
      className="sales-overview-chart"
      data={Overview}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            displayColors: false,
            backgroundColor: "#eff6ff",
            titleFont: {
              size: 13,
            },
            titleColor: "#6783b8",
            bodyFont: {
              size: 12,
            },
            bodyColor: "#9eaecf",
            bodySpacing: 4,
            padding: 10,
          },
        },
        maintainAspectRatio: false,
        scales: {
          y: {
            display: true,
            ticks: {
              beginAtZero: true,
              color: "#9eaecf",
              font: {
                size: 11,
              },
              callback: (value) => value,
              padding: 10,
            },
            grid: {
              tickMarkLength: 0,
            },
          },
          x: {
            display: true,
            ticks: {
              color: "#9eaecf",
              font: {
                size: 9,
              },
              padding: 10,
            },
            grid: {
              color: "transparent",
            },
          },
        },
      }}
    />
  );
};
