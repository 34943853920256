import React, { useEffect, useState } from "react";
import FileSelection2 from "./FileSelection2";
import { Row, Col, Button } from "reactstrap";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Utils";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";
import { useQuery, useQueryClient } from "react-query";
import { BlockTitle } from "../../../components/Component";
import { Link } from "react-router-dom";
import UploadImage from "./UploadImage";

const LogoChange = () => {
  const [data, setData] = useState(null);
  const [btnloader, setButtonloader] = useState(false);
  const queryClient = useQueryClient();
  const [formDataEdit, setFormDataEdit] = useState({
    landing_page_title: "",
    blog_title: "",
    country_preference: "",
    support_phone: "",
    currency: "",
    support_email: "",
    budget_min_price: "",
    budget_max_price: "",
  });
  const [croppedLogo, setCroppedLogo] = useState(null);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, {
        method: "POST",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      
      setData(responseData.data);

      // Update formData with fetched values
      const contactSettings = responseData.data.site_settings.contact; // For contact settings
      const otherSettings = responseData.data.site_settings.other; // For other settings
      //console.log("other",otherSettings);
      
      const settingsMap = {
        landing_page_title: otherSettings.landing_page_title || "", // Keep it blank if empty
        blog_title: otherSettings.blog_title || "",
        currency: otherSettings.currency || "",
        support_phone: contactSettings.support_phone || "",
        support_email: contactSettings.support_email || "",
        country_preference: contactSettings.country_preference || "",
        budget_min_price: otherSettings.budget_min_price || "",
        budget_max_price: otherSettings.budget_max_price || "",
      };
      setFormDataEdit(settingsMap);
      
      // console.log("Data from backend -> ", settingsMap);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Site settings
  const {
    data: logosData,
    isLoading: logosLoading,
    isError: logosError,
  } = useQuery({
    queryKey: ["get-logos"],
    queryFn: fetchData,
  });

  // Function to handle the cropped image
  const handleCroppedLogo = (croppedImage) => {
    setCroppedLogo(croppedImage); // Update the croppedLogo state
    // console.log("Cropped logo:", croppedImage);
  };

  const handleSubmit = () => {
    if (parseFloat(formDataEdit.budget_min_price) > parseFloat(formDataEdit.budget_max_price)) {
      toast.error("Minimum budget cannot be greater than Maximum budget.");
      return;
    }
    if (parseFloat(formDataEdit.budget_min_price) < 0 || parseFloat(formDataEdit.budget_max_price) < 0) {
      toast.error("Budget values cannot be negative.");
      return;
    }
    if (emailError || !formDataEdit.support_email) {
      toast.error("Please provide a valid support email.");
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You want to save it",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, save it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setButtonloader(true);
        const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

        const formData = new FormData();

        // Add form data fields
        for (const [key, value] of Object.entries(formDataEdit)) {
          formData.append(key, value === "" ? null : value); // Replace empty string with null
        }
        
        // Check if cropped logo is present and valid
        if (croppedLogo) {
          const logoBlob = await fetch(croppedLogo).then((res) => res.blob());
          formData.append("blog_header_image", logoBlob);
        }

        const isValidFileType = (file) => allowedFileTypes.includes(file.type);

        const handleFileInput = (inputElement, formDataKey) => {
          const file = inputElement.files[0];
          if (file) {
            if (isValidFileType(file)) {
              formData.append(formDataKey, file);
            } else {
              toast.error(`Invalid file type: ${file.type}. Allowed types are jpg, png, jpeg, gif.`);
            }
          }
        };

        // Append logo files
        handleFileInput(document.getElementById("sidebarLogoId"), "sidebar_logo");
        handleFileInput(document.getElementById("faviconLogoId"), "favicon");
        handleFileInput(document.getElementById("publicLogoId"), "public_logo");
        handleFileInput(document.getElementById("clienthomepageLogoId"), "client_home_logo");
        handleFileInput(document.getElementById("authLogoId"), "logo");

        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-site-setting`, {
            method: "POST",
            headers: {
              authToken: getCookie("authToken"),
            },
            body: formData,
          });

          if (response.ok) {
            toast.success("Form and files submitted successfully!");
          } else {
            const errorData = await response.json();
            toast.error(`Error: ${errorData.message || "Failed to submit form and files"}`);
          }
        } catch (error) {
          console.error("API call error:", error);
          toast.error("An error occurred during form submission.");
        } finally {
          setButtonloader(false);
          queryClient.invalidateQueries("get-logos");
        }
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataEdit((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [emailError, setEmailError] = useState("");

  const validateEmail = (e) => {
    const email = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  
  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-3 mx-3">
        <div>
          <BlockTitle tag="h4">Logo Selection</BlockTitle>
          <span>Logo Selection for specific sections</span>
        </div>
        <Link to="/site-setting">
          <Button className="toggle" color="primary" disabled={logosLoading} style={{ marginRight: "10px" }}>
            <span>Back</span>
          </Button>
        </Link>
      </div>
      {logosLoading ? (
        <ShimmerSimpleGallery card imageHeight={250} caption row={1} col={4} />
      ) : (
        <div className="row border border-grey mx-2 my-2 d-flex justify-center">
          {data && (
            <>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-start flex-column my-2">
                  <div className="fs-18px fw-bold">Sidebar Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.sidebar_logo}`}
                    inputId="sidebarLogoId"
                  />
                </div>
                {/* { console.log('Sidebar Logo', `${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.sidebar_logo}`)} */}
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Favicon Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.favicon}`}
                    inputId="faviconLogoId"
                  />
                </div>
                {/* { console.log('Favicon:', `${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.favicon}`)} */}
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Public Page Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.public_logo}`}
                    inputId="publicLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Client Dashboard Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.client_home_logo}`}
                    inputId="clienthomepageLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Auth Pages Logo</div>
                  <FileSelection2
                    filePath={`${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.logo}`}
                    inputId="authLogoId"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-center flex-column my-2">
                  <div className="fs-18px fw-bold">Upload Blog Image</div>
                  <UploadImage
                    label=" "
                    name="blogImage"
                    filePath={`${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.blog_header_image}`}
                    handleCroppedImage={handleCroppedLogo} // Pass the handler to UploadImage
                  />
                </div>
                {/* {console.log(
                  "Blog Header Image:",
                  `${process.env.REACT_APP_API_URL}${data?.site_settings?.meta?.blog_header_image}`
                )} */}
              </div>
            </>
          )}

          {/* Form */}
          <form className="p-5">
            {/* Row 1 - Title */}
            <Row className="align-items-center g-2 mb-4 mt-3">
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="landing_page_title ">Landing page Title:</label>
                </div>
                <input
                  type="text"
                  name="landing_page_title"
                  value={formDataEdit.landing_page_title}
                  onChange={handleInputChange}
                  placeholder="Enter title"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="blog_title ">Blog Title:</label>
                </div>
                <input
                  type="text"
                  name="blog_title"
                  value={formDataEdit.blog_title}
                  onChange={handleInputChange}
                  placeholder="Enter blog page title"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
            </Row>

            {/* Row 2 - Country Preference & Support Phone */}
            <Row className="align-items-center g-2 mb-4">
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="country_preference">Country Preference:</label>
                </div>
                <input
                  type="text"
                  name="country_preference"
                  value={formDataEdit.country_preference}
                  onChange={handleInputChange}
                  placeholder="Enter country preference"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="support_phone">Support Phone:</label>
                </div>
                <input
                  type="text"
                  name="support_phone"
                  value={formDataEdit.support_phone}
                  onChange={handleInputChange}
                  placeholder="Enter support phone"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
            </Row>

            {/* Row 3 - Currency & Support Email */}
            <Row className="align-items-center g-2 mb-4">
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="currency">Currency:</label>
                </div>
                <input
                  type="text"
                  name="currency"
                  value={formDataEdit.currency}
                  onChange={handleInputChange}
                  placeholder="Enter currency"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
              <Col xs="12" sm="6">
  <div className="form-label-cs pb-2">
    <label htmlFor="support_email">Support Email:</label>
  </div>
  <input
    type="email"
    name="support_email"
    value={formDataEdit.support_email}
    onChange={handleInputChange}
    onBlur={validateEmail} // Add email validation on blur
    placeholder="Enter support email"
    required
    className={`form-control form-control-cs rounded p-2 ${
      emailError ? "is-invalid" : ""
    }`}
  />
  {emailError && <div className="invalid-feedback">{emailError}</div>}
</Col>

            </Row>

            {/* Row 4 - Budget Minimum & Maximum */}
            <Row className="align-items-center g-2 mb-4">
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="budget_min_price">Budget Minimum:</label>
                </div>
                <input
                  type="number"
                  name="budget_min_price"
                  value={formDataEdit.budget_min_price}
                  onChange={handleInputChange}
                  placeholder="Enter minimum budget"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
              <Col xs="12" sm="6">
                <div className="form-label-cs pb-2">
                  <label htmlFor="budget_max_price">Budget Maximum:</label>
                </div>
                <input
                  type="number"
                  name="budget_max_price"
                  value={formDataEdit.budget_max_price}
                  onChange={handleInputChange}
                  placeholder="Enter maximum budget"
                  required
                  className="form-control form-control-cs rounded p-2"
                />
              </Col>
            </Row>
            <div className="row my-2">
              <div className="d-flex align-items-center justify-content-center">
                <Button color="primary" onClick={handleSubmit} disabled={btnloader}>
                  {btnloader ? <PuffLoader speedMultiplier={3} size={25} color="#39ff00" /> : "SAVE"}
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default LogoChange;
