import React, { useEffect, useState } from "react";
import { Icon, Button, Col, Row } from "../../components/Component";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import PuffLoader from "react-spinners/PuffLoader";

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" style={{ width: "20px", marginRight: "10px" }} />
    {props.data.label}
  </components.Option>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <img src={props.data.icon} alt="s-logo" className="selected-logo" style={{ width: "20px", marginRight: "10px" }} />
    {children}
  </components.SingleValue>
);

const SongListModels = ({ view, modalType, formData, setFormData, onFormCancel, onSubmit, loading }) => {
 
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch("password");

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <Modal isOpen={view} toggle={onFormCancel} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Add Information" : "Update Information"}</h5>
          <div className="mt-4">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
              <Col size="12">
  <div className="form-group">
    <label className="form-label" htmlFor="title">
      Song name
    </label>
    <input
      type="text"
      className={`form-control ${errors.title ? "is-invalid" : ""}`}
      placeholder="Enter song name"
      {...register("title", {
        required: "This field is required",
        minLength: {
          value: 3,
          message: "Song name must be at least 3 characters long",
        },
        maxLength: {
          value: 255,
          message: "Song Name cannot exceed 255 characters",
        },
      })}
      value={formData.title}
      onChange={(e) => setFormData({ ...formData, title: e.target.value })}
    />
    {errors.title && <span className="invalid">{errors.title.message}</span>}
  </div>
</Col>

                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="artist">
                      Artist name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter artist name"
                      {...register("artist", {
                        //required: "This field is required",
                        minLength: {
                          value: 2,
                          message: "Artist Name must be at least 2 characters long",
                        },
                        maxLength: {
                          value: 100,
                          message: "Artist Name cannot exceed 100 characters",
                        },
                      })}
                      maxLength={50}
                      value={formData.artist}
                      onChange={(e) => setFormData({ ...formData, artist: e.target.value })}
                    />
                    {errors.artist && <span className="invalid">{errors.artist.message}</span>}
                  </div>
                </Col>
                
                <Col md="12">
  <div className="form-group">
    <label className="form-label" htmlFor="music_provider_link">
      Music link
    </label>
    <input
      type="text"
      className={`form-control ${errors.music_provider_link ? "is-invalid" : ""}`}
      placeholder="Enter music link"
      {...register("music_provider_link", {
        //required: "This field is required",
        pattern: {
          value: /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-._~:/?#[\]@!$&'()*+,;=]*)?$/,
          message: "Please enter a valid music URL",
        },
      })}
      maxLength={255}
      value={formData.music_provider_link}
      onChange={(e) => setFormData({ ...formData, music_provider_link: e.target.value })}
    />
    {errors.music_provider_link && (
      <span className="invalid">{errors.music_provider_link.message}</span>
    )}
  </div>
</Col>

                <Col size="12" className="d-flex justify-center flex-column flex-md-row">
  <Button color="primary" type="submit" disabled={loading}>
    <Icon className="plus" />
    <span className="mx-auto px-0">
      {modalType === "add" ? "Add Song" : "Update"}
    </span>
    {loading && <PuffLoader speedMultiplier={2} size={30} color="#39ff00" />}
  </Button>
</Col>

              </Row>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SongListModels;
