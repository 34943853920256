import React, { useState, useEffect } from "react";
import { BlockTitle } from "../../components/Component";
import { Link } from "react-router-dom";
import { Button, Col, Input, Row, Form } from "reactstrap";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";

function MetaInformation() {
  const [formData, setFormData] = useState({
    description: "",
    ogTitle: "",
    ogDescription: "",
    ogUrl: "",
    twitterTitle: "",
    twitterDescription: "",
    meta_keywords: "",
    meta_image: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const fetchMetaData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-meta-data`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        const meta = data?.data || [];
        const metaObj = meta.reduce((acc, item) => {
          acc[item.field] = item.value || "";
          return acc;
        }, {});

        setFormData((prevData) => ({
          ...prevData,
          ...metaObj,
        }));
      } else {
        toast.error("Failed to fetch meta information.");
      }
    } catch (error) {
      console.error("Error fetching meta data:", error);
      toast.error("An error occurred while fetching meta data.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMetaData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        meta_image: file, 
        previewImage: URL.createObjectURL(file), 
      }));
    }
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,4}(\/[^\s]*)?$/;
  if (formData.ogUrl && !urlPattern.test(formData.ogUrl)) {
    toast.error("Please enter a valid OG URL.");
    return; // Stop the form submission
  }

    setIsLoading(true);
  
    const payload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "meta_image" || value instanceof File) {
        payload.append(key, value);
      } else if (value) {
        payload.append(key, value);
      }
    });
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-update-meta-data`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: payload,
      });
  
      const result = await response.json();
      if (response.ok) {
        toast.success("Data saved successfully.");
        setFormData((prevData) => ({
          ...prevData,
          meta_image: result.data?.meta_image || prevData.meta_image, 
          previewImage: null, 
        }));
        await fetchMetaData();
      } else {
        toast.error(result.message || "Failed to save data.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to save data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  
  
  

  return (
    <>
      <div className="d-flex align-items-center justify-content-between my-3 mx-3">
        <div>
          <BlockTitle tag="h4">Meta Information</BlockTitle>
        </div>
        <Link to="/site-setting">
          <Button className="toggle" color="primary" style={{ marginRight: "10px" }}>
            <span>Back</span>
          </Button>
        </Link>
      </div>
      <div className="d-flex justify-content-center">
        <div className="row border border-grey mx-5 my-5 d-flex justify-center py-2 w-75 bg-white">
          <Form onSubmit={handleSubmit}>
            <Row className="p-3">
              <Row>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">Description</label>
                  <Input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter Description"
                    className="form-control"
                  />
                </Col>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">OG Title</label>
                  <Input
                    type="text"
                    name="ogTitle"
                    value={formData.ogTitle}
                    onChange={handleInputChange}
                    placeholder="Enter OG Title"
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">OG Description</label>
                  <Input
                    type="text"
                    name="ogDescription"
                    value={formData.ogDescription}
                    onChange={handleInputChange}
                    placeholder="Enter OG Description"
                    className="form-control"
                  />
                </Col>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">OG URL</label>
                  <Input
                    type="text"
                    name="ogUrl"
                    value={formData.ogUrl}
                    onChange={handleInputChange}
                    placeholder="https://example.com"
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">Twitter Title</label>
                  <Input
                    type="text"
                    name="twitterTitle"
                    value={formData.twitterTitle}
                    onChange={handleInputChange}
                    placeholder="Enter Twitter Title"
                    className="form-control"
                  />
                </Col>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">Twitter Description</label>
                  <Input
                    type="text"
                    name="twitterDescription"
                    value={formData.twitterDescription}
                    onChange={handleInputChange}
                    placeholder="Enter Twitter Description"
                    className="form-control"
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="d-flex flex-column my-3">
                  <label className="form-label">Meta Keywords</label>
                  <Input
                    type="text"
                    name="meta_keywords"
                    value={formData.meta_keywords}
                    onChange={handleInputChange}
                    placeholder="Enter Meta Keywords"
                    className="form-control"
                  />
                </Col>
                <Col md="6" className="d-flex flex-column my-3">
  <label className="form-label">Meta Image</label>
  <Input type="file" name="meta_image" onChange={handleFileChange} />
  <div className="img-fluid d-flex justify-content-center">
  {(formData.previewImage || formData.meta_image) && (
    <img
      src={
        formData.previewImage
          ? formData.previewImage
          : `${process.env.REACT_APP_API_URL}${formData.meta_image}?t=${new Date().getTime()}` 
      }
      alt="Meta Preview"
      className="rounded-5 h-100px w-300px object-fit-contain mt-2"
    />
  )}
</div>

</Col>


              </Row>
              <div className="d-flex justify-content-center pe-5 mt-4">
                <Button className="toggle" color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? "Saving..." : "Save"}
                </Button>
              </div>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}

export default MetaInformation;
