import React from 'react'; 
import { Container, Row, Col, Card } from 'reactstrap';
import { Link, Outlet } from 'react-router-dom';
import settingLogo from '../../../images/settings_logo/Site-setting.png';
import cate_ser_logo from '../../../images/settings_logo/category-services.png';
import payment_gateway from '../../../images/settings_logo/payment-gateway.png';
import Demo from '../../../images/settings_logo/meta_demo.png'
export default function Settings() {
  return (
    <Container className="my-5">
      <Row className="g-5">
        {/* Column 1 - Logo Change */}
        <Col xs="12" md="6" lg="3">
          <Card className="p-5 text-center border h-100" style={{ cursor: 'pointer' }}>
            <Link to="logoChange"> 
              <img src={settingLogo} alt="setting" className='img-fluid'/>
              <h6 className="mt-4">Settings</h6>
            </Link>
          </Card>
        </Col>

        {/* Column 2 - Category and Services Manager */}
        <Col xs="12" md="6" lg="3">
          <Card className="p-5 text-center border h-100">
            <Link to="categorymanager">
              <img src={cate_ser_logo} alt="setting" className='img-fluid'/>
              <h6 className="mt-4">Category and Services Manager</h6>
            </Link>
          </Card>
        </Col>

        {/* Column 3 - Payment Gateway Settings */}
        <Col xs="12" md="6" lg="3">
          <Card className="p-5 text-center border h-100">
            <Link to="paymentgateway">
              <img src={payment_gateway} alt="setting" className='img-fluid'/>
              <h6 className="mt-4">Payment Gateway Settings</h6>
            </Link>
          </Card>
        </Col>

        {/* Column 4 - Meta Information */}
        <Col xs="12" md="6" lg="3">
          <Card className="p-5 text-center border h-100">
            <Link to="meta_information">
              <img src={Demo} alt="meta information"  className='img-fluid'/>
              <h6 className="mt-4">Meta Information</h6>
            </Link>
          </Card>
        </Col>
      </Row>

      {/* Outlet for rendering nested routes */}
      <Outlet />
    </Container>
  );
}
