import React, { useEffect, useState } from "react";
import { CompanyGraph } from "./companyGraph";
import { getCookie } from "../../../utils/Utils";
import { CardTitle } from "reactstrap";

const CompanyAnylatics = () => {
  const [filterData, setFilterData] = useState(null);
  const [checkFilter, setCheckFilter] = useState("monthly");
  const [registeredCompanies, setRegisteredCompanies] = useState(0);

  const companiesFilter = async (filterValue) => {
    try {
      setCheckFilter(filterValue);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin-dashboard/get-admin-dashboard-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: getCookie("authToken"),
          },
          body: JSON.stringify({ filter_value: filterValue }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const filterDataResponse = await response.json();

      if (filterDataResponse.status) {
        setFilterData(filterDataResponse?.data?.companies);
      } else {
        console.log("Error in response data:", filterDataResponse);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  useEffect(() => {
    const countfilterdata = filterData?.datasets?.[0]?.data;

    if (countfilterdata) {
      const companyCount = countfilterdata.reduce((sum, count) => sum + count, 0);
      setRegisteredCompanies(companyCount);
    } else {
      setRegisteredCompanies(0);
    }
  }, [filterData]);

  useEffect(() => {
    companiesFilter(checkFilter); // Initial fetch with default filter
  }, []);

  return (
    <React.Fragment>
      <div className="card-title-group align-start gx-3 mb-3">
        <CardTitle>
          <h6 className="title fs-4 fw-medium">
            {checkFilter === "monthly"
              ? "Monthly"
              : checkFilter === "3 months"
              ? "Last 3 Months"
              : "Yearly"}{" "}
            Companies Registration
          </h6>
          <p>
            Companies Registered in{" "}
            {checkFilter === "monthly"
              ? "Last Month"
              : checkFilter === "3 months"
              ? "Last 3 Months"
              : "Last Year"}
          </p>
        </CardTitle>
        <div className="card-tools ps-0 ms-0">
          <div className="card-tools shrink-0 w-150px d-sm-block">
            <ul className="nav nav-switch-s2 nav-tabs bg-white justify-center row m-2 m-sm-0">
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={
                    checkFilter === "monthly"
                      ? "nav-link text-primary bg-lighter"
                      : "nav-link"
                  }
                  style={{ width: "44.39px", height: "29.6px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={(e) => {
                    e.preventDefault();
                    companiesFilter("monthly");
                  }}
                >
                  1 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={
                    checkFilter === "3 months"
                      ? "nav-link text-primary bg-lighter"
                      : "nav-link"
                  }
                  style={{ width: "44.39px", height: "29.6px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={(e) => {
                    e.preventDefault();
                    companiesFilter("3 months");
                  }}
                >
                  3 M
                </a>
              </li>
              <li className="nav-item col-12 col-sm-4 justify-end">
                <a
                  href="#navitem"
                  className={
                    checkFilter === "yearly"
                      ? "nav-link text-primary bg-lighter"
                      : "nav-link"
                  }
                  style={{ width: "44.39px", height: "29.6px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={(e) => {
                    e.preventDefault();
                    companiesFilter("yearly");
                  }}
                >
                  1 Y
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="nk-sale-data-group gy-3 gx-5 row">
        <div className="nk-sale-data col-8">
          <div className="amount sm row">
            <span className="sub-title fs-6 d-flex no-wrap">
              Total Count of Companies:
            </span>
            <span className="fs-4">{registeredCompanies} Subscribers</span>
          </div>
        </div>
      </div>
      <div className="nk-sales-ck large pt-4 h-200px">
        <CompanyGraph filterData={filterData} />
      </div>
    </React.Fragment>
  );
};

export default CompanyAnylatics;
