import React, { useEffect } from "react";
import { Icon, Button, Col, Row } from "../../components/Component";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import PuffLoader from "react-spinners/PuffLoader";

const PaymentListModels = ({ view, modalType, formData, setFormData, onFormCancel, onSubmit, loading, paymentSettings }) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  //console.log("formData:", formData);

  const handleEnvironmentChange = (e) => {
    const selectedEnvironment = e.target.value;
    const newEnvironmentAction = selectedEnvironment === "Live" ? "1" : "0"; 
    setFormData({
      ...formData,
      active_environment: newEnvironmentAction,
    });
  };

  return (
    <Modal isOpen={view} toggle={onFormCancel} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
        >
          <Icon name="cross-sm" />
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Add Payment" : "Update Payment"}</h5>
          <div className="mt-4">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
                <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="payment_type">
                      Payment Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Payment Type"
                      {...register("payment_type", {
                        required: "This field is required",
                      })}
                      maxLength={50}
                      value={formData.payment_type}
                      onChange={(e) => setFormData({ ...formData, payment_type: e.target.value })}
                    />
                    {errors.payment_type && <span className="invalid">{errors.payment_type.message}</span>}
                  </div>
                </Col>

                {/* Environment Selection */}
                <div className="mt-4">
                  <div className="d-flex flex-lg-row align-items-start lg:align-items-center">
                    <div className="form-check ps-1">
                      <input
                        type="radio"
                        id="sandbox"
                        name="environment"
                        value="Sandbox"
                        checked={formData.active_environment === "0"}
                        onChange={handleEnvironmentChange}
                        className="form-check-input"
                      />
                      <label htmlFor="sandbox" className="form-check-label">
                        Sandbox
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        type="radio"
                        id="live"
                        name="environment"
                        value="Live"
                        checked={formData.active_environment === "1"}
                        onChange={handleEnvironmentChange}
                        className="form-check-input"
                      />
                      <label htmlFor="live" className="form-check-label">
                        Live
                      </label>
                    </div>
                  </div>
                </div>

                {/* Sandbox Environment Fields */}
                {formData.active_environment === "0" && (
                  <Row className="align-items-center g-2 mb-4">
                    <Col xs="12" sm="6">
                      <label className="form-label" htmlFor="clientKey">
                        Client Key
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.sanbox_client_key}
                        onChange={(e) => setFormData({ ...formData, sanbox_client_key: e.target.value })}
                        placeholder={`Enter Client Key`}
                        name="clientKey"
                        required
                      />
                    </Col>

                    <Col xs="12" sm="6">
                      <label className="form-label" htmlFor="secretKey">
                        Secret Key
                      </label>
                      <input
                        type="text"
                        name="secretKey"
                        value={formData.sandbox_secret_key}
                        onChange={(e) => setFormData({ ...formData, sandbox_secret_key: e.target.value })}
                        className="form-control"
                        placeholder={`Enter Secret Key`}
                        required
                      />
                    </Col>
                  </Row>
                )}

                {/* Live Environment Fields */}
                {formData.active_environment === "1" && (
                  <Row className="align-items-center g-2 mb-4">
                    <Col xs="12" sm="6">
                      <label className="form-label" htmlFor="clientKey">
                        Client Key
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.live_client_key}
                        onChange={(e) => setFormData({ ...formData, live_client_key: e.target.value })}
                        placeholder={`Enter Client Key`}
                        name="clientKey"
                        required
                      />
                    </Col>

                    <Col xs="12" sm="6">
                      <label className="form-label" htmlFor="secretKey">
                        Secret Key
                      </label>
                      <input
                        type="text"
                        name="secretKey"
                        value={formData.live_secret_key}
                        onChange={(e) => setFormData({ ...formData, live_secret_key: e.target.value })}
                        className="form-control"
                        placeholder={`Enter Secret Key`}
                        required
                      />
                    </Col>
                  </Row>
                )}

                {/* Submit Button */}
                <Col size="12" className="d-flex justify-start">
                  <Button color="primary" type="submit" disabled={loading}>
                    <Icon className="plus" />
                    <span className="mx-auto px-0">
                      {modalType === "add" ? "Add" : "Update"}
                    </span>
                    {loading && <PuffLoader speedMultiplier="2" size="30px" color={"#39ff00"} />}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentListModels;
