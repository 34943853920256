import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Modal, ModalBody } from "reactstrap";
import { BlockTitle, Col, Icon, ReactDataTable, Row } from "../../components/Component";
import NavigationHeader from "../components/NavigationHeader";
import { useQuery } from "react-query"; // Ensure this import is correct
import { getCompanyList, getCountries, getEventType, getEventsList, getStates } from "../../http/get/getApi";
import { formatDate, getCookie, transformData } from "../../utils/Utils";
import { toast } from "react-toastify";
import PuffLoader from "react-spinners/PuffLoader";
import ReactSelect from "react-select";

export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const EventListPage = () => {
  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [countryName, setCountryName] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [eventTypeVal, setEventTypeVal] = useState(null);
  const [companyVal, setCompanyVal] = useState(null);
  const [stateVal, setStateVal] = useState(null);
  const [isClearable, setIsClearable] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [filteredData, setFilteredData] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0); 
  
  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });

  
  // Fetching companies list
  const {
    data: companies,
    isLoading: companiesLoading,
    isError: companiesError,
  } = useQuery({
    queryKey: ["get-companies"],
    queryFn: () => getCompanyList(),
  });
  const filteredCompanies = companies?.data?.list?.filter((company) => company.role_id == 2) || [];
  const companyOptions = transformData({
    dataArray: filteredCompanies,
    label: "company_name",
    value: "id",
    isLoading: companiesLoading,
  });

  // Fetching eventTypes data
  const { data: eventTypes, isLoading: eventTypesLoading, isError: eventTypesError } = useQuery({
    queryKey: ["get-eventTypes"],
    queryFn: () => getEventType({ type: "1" }),
    
  });
  
  
  
  const eventTypesOptions = transformData({
    dataArray: eventTypes?.data?.Category || [],
    label: "title", // Correctly map "title" from the response
    value: "id",    // Use `id` as the value
    isLoading: eventTypesLoading,
  });

  // Fetching countries list
  const {
    data: country,
    isLoading: countryLoading,
    isError: countryError,
  } = useQuery({
    queryKey: ["get-countries"],
    queryFn: () => getCountries(),
  });

  const countriesOptions = transformData({
    dataArray: country?.data?.countries || [],
    label: "name",
    value: "id",
    isLoading: countryLoading,
  });

  // Fetching states list
  const {
    data: states,
    isLoading: statesLoading,
    isError: statesError,
  } = useQuery({
    queryKey: ["get-states-by-country", selectedCountry],
    queryFn: () => getStates({ id: selectedCountry }),
    enabled: !!selectedCountry,
  });

  const statesOptions = transformData({
    dataArray: states?.data?.states || [],
    label: "name",
    value: "id",
    isLoading: statesLoading,
  });

  function handleEventType(e) {
    setEventTypeVal(e);
  }

  function handleCompany(e) {
    setCompanyVal(e);
  }

  function handleCountry(e) {
    setCountryName(e);
    setSelectedCountry(e.value);
  }

  function handleState(e) {
    setStateVal(e);
  }

  const toggleModal = () => {
    setView({ ...view, edit: !view.edit });
    setEventTypeVal(null);
    setCompanyVal(null);
    setCountryName(null);
    setSelectedCountry(null);
    setStateVal(null);
  };
  //console.log("its page:",pageNo);
  
  const FilterApiCall = async () => {
    setLoadingFilter(true);
    //setPageNo(0);  
    setFilteredData([]);
    try {
      const formData = new FormData();
      if (companyVal?.value) {
        formData.append("company", companyVal.value);
      }
      if (stateVal?.value) {
        formData.append("state", stateVal.value);
      }
      if (eventTypeVal?.value) {
        formData.append("event_type", eventTypeVal.value);
      }
      if (countryName?.value) {
        formData.append("country", countryName.value);
      }
      formData.append("limit", recordLimit);
      formData.append("search",search)
      formData.append("offset",pageNo+1)
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-companies-filter`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch filtered data");
      }
      const dataFilter = await response.json();
      setFilteredData(dataFilter?.data?.eventData || []);
      setView({ ...view, edit: false }); 
      setTotalRecords(dataFilter?.data?.total || 0);
    } catch (error) {
      toast.error("Error fetching filtered data. Please try again later.");
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoadingFilter(false);
    }
  };
  
  useEffect(() => {
    FilterApiCall();
  }, [pageNo,recordLimit,search]);
  //console.log("page no",pageNo);
  
  const tableHeadings = [
    {
      name: "Event Id",
      selector: (row, index) => ((pageNo) * recordLimit) + index + 1  || "",
      maxWidth: "5px",
    },
    {
      name: "Company Name",
      selector: (row) =>
        // `${row.first_name || ""} ${row.last_name || ""}`.trim() ||
        `${row?.company_name || ""}`.trim() || <NotAvailable />,
    },
    {
      name: "Address",
      selector: (row) => row?.address_1 || <NotAvailable />,
    },
    {
      name: "Event Name",
      selector: (row) => row.event_name || <NotAvailable />,
    },
    {
      name: "Event Type",
      selector: (row) => row.event_type_title || <NotAvailable />,
    },
    {
      name: "Event Start-Date",
      selector: (row) => formatDate(row.start_date_time) || <NotAvailable />,
    },
    {
      name: "Event End-Date",
      selector: (row) => formatDate(row.end_date_time) || <NotAvailable />,
    },
  ];

  return (
    <>
      <NavigationHeader heading={""} />
      <div className="card-inner d-flex justify-content-between">
        <BlockTitle className="mb-0">Event List</BlockTitle>
        <Button className="btn-dim bg-primary text-white px-2 p-0" onClick={toggleModal}>
          <Icon name="filter"></Icon>
          <span className="sub-text text-white">Filter</span>
        </Button>
      </div>

      <section className="m-3 border p-3 bg-white">
        <ReactDataTable
          data={filteredData}
          columns={tableHeadings}
          searchBy={"event_name"}
          pagination
          actions
          pageNo={setPageNo}
          setPageNo={setPageNo}
          loading={loadingFilter}
          setSearch={setSearch}
          filter
          recordLimit={setRecordLimit}
          totalRecords={totalRecords}
          activePage={pageNo || 0}
        />
      </section>
      <Modal isOpen={view.edit} className="modal-dialog-centered" size="lg" toggle={toggleModal}>
        <ModalBody>
          <a href="#cancel" className="close" onClick={toggleModal}>
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Filter</h5>
            <div className="mt-4">
              <form noValidate>
                <Row className="g-3 mb-3">
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Company
                      </label>
                      <ReactSelect options={companyOptions} value={companyVal} onChange={(e) => handleCompany(e)} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Event Types
                      </label>
                      <ReactSelect
                        options={eventTypesOptions}
                        value={eventTypeVal}
                        isClearable={isClearable}
                        onChange={(e) => handleEventType(e)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="g-3">
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        Country
                      </label>
                      <ReactSelect options={countriesOptions} value={countryName} onChange={(e) => handleCountry(e)} />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="product-title">
                        State
                      </label>
                      <ReactSelect options={statesOptions} value={stateVal} onChange={(e) => handleState(e)} />
                    </div>
                  </Col>
                </Row>
                <Row className="g-3 mt-3">
                  <Col md={6}>
                    <Button color="primary" onClick={FilterApiCall}>
                      <span className="px-0 mx-0">Filter</span>
                      {loadingFilter ? <PuffLoader speedMultiplier="2" size="30" color={"#39ff00"} /> : ""}
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EventListPage;
